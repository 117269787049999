<template>
  <div class="info-card" :style="{background: `linear-gradient(135deg, ${item.c1} 0%, ${item.c2} 100%)`}">
    <div class="left">
      <div class="label">{{item.label}}</div>
      <div class="count">{{item.count}}</div>
    </div>
    <div :class="['right','iconfont', item.icon]">
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoCard',
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>