export const rolesLists = [
  {
    id: 0,
    name: '普通用户1',
    time: '2020-12-12 12:44:23',
    description: '角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述',
    selected: true,
    analyse: false,
    configAuth: []
  },
  {
    id: 1,
    name: '普通用户2',
    time: '2020-12-12 12:44:23',
    description: '角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述',
    selected: false,
    analyse: true,
    configAuth: [1]
  },
  {
    id: 2,
    name: '普通用户3',
    time: '2020-12-12 12:44:23',
    description: '角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述',
    selected: false,
    analyse: true,
    configAuth: [1]
  },
  {
    id: 3,
    name: '普通用户4',
    time: '2020-12-12 12:44:23',
    description: '角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述角色描述',
    selected: false,
    analyse: false,
    configAuth: []
  }
]

export const addItem = {
  name: '',
  description: '',
  has_analysis: false
}