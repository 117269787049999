import { BaseServe } from '@/server'

// 获取文献类型列表
const fetchLiteratureType = (params) => {
  return BaseServe({
    url: '/api/article_types/',
    params
  })
}

// 获取疾病领域列表
const fetchFields = (params) => {
  return BaseServe({
    url: '/api/fields/',
    params
  })
}

// 获取生物标志物列表
const fetchBiomarkers = (params) => {
  return BaseServe({
    url: '/api/bio_markers/',
    params
  })
}

// 获取疾病列表
const fetchDisease = (params) => {
  return BaseServe({
    url: '/api/diseases/',
    params
  })
}

// 获取临床应用列表
const fetchClinicals = (params) => {
  return BaseServe({
    url: '/api/clinical_applications/',
    params
  })
}

// 获取人群列表
const fetchPopulations = (params) => {
  return BaseServe({
    url: '/api/populations/',
    params
  })
}

// 上传内容
const submitGuideContent = (data) => {
  return BaseServe({
    url: '/api/create/guide/',
    method: 'post',
    data
  })
}

// 获取上传数据列表
const getUploads = (params) => {
  return BaseServe({
    url: '/api/uploads/',
    params,
    loading: false
  })
}

// 获取数据详情
const getUploadDataDetail = (id) => {
  return BaseServe({
    url: `/api/upload/${id}/`
  })
}

// 删除数据
const deleteUploadData = (id) => {
  return BaseServe({
    url: `/api/upload/${id}/`,
    method: 'delete'
  })
}

// 修改数据
const putUploadData = (id, data) => {
  return BaseServe({
    url: `/api/upload/${id}/`,
    method: 'put',
    data
  })
}

// 文献上传
const submitLiterature = (data) => {
  return BaseServe({
    url: '/api/create/article/',
    method: 'post',
    data
  })
}

// 指南批量上传
const uploadBatchGuide = (data) => {
  return BaseServe({
    url: '/api/create/guide/batch/',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data,
    loading: false
  })
}

// 文献批量上传
const uploadBatchArticle = (data) => {
  return BaseServe({
    url: '/api/create/article/batch/',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data,
    loading: false
  })
}

// 批量删除
const deleteUploadsData = (data) => {
  return BaseServe({
    url: '/api/uploads/',
    method: 'delete',
    data
  })
}

export {
  fetchLiteratureType,
  fetchFields,
  fetchBiomarkers,
  fetchDisease,
  fetchClinicals,
  fetchPopulations,
  submitGuideContent,
  submitLiterature,
  getUploads,
  getUploadDataDetail,
  deleteUploadData,
  putUploadData,
  uploadBatchGuide,
  uploadBatchArticle,
  deleteUploadsData
}