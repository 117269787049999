<template>
  <Modal :value="value" v-bind="$attrs" @on-visible-change="handleVisible">
    <transition name="fade">
      <div v-if="value" class="form-wrap">
        <Form
          ref="formRef"
          :model="form"
          :rules="ruleValidate"
          label-position="right"
          :label-width="140"
          style="padding-right: 20px;"
        >
          <Row>
            <Col span="12">
              <FormItem label="主题生物标志物" prop="biomarker">
                <Select v-model="form.biomarker" multiple placeholder="请选择主题生物标志物" @on-change="handleChange">
                  <Option v-for="item in $store.state.zhiku.biomarkers" :key="item.id" :value="item.id">{{
                    item.label
                  }}</Option>
                </Select>
              </FormItem>
              <FormItem label="标题">
                <Input v-model="form.title" placeholder="请输入标题" />
              </FormItem>
              <FormItem label="作者">
                <Row :gutter="8" style="margin-bottom: 12px;">
                  <Col span="12">
                    <Input v-model="form.authors[0].name" placeholder="请输入通讯作者1" />
                  </Col>
                  <Col span="12">
                    <Input v-model="form.authors[0].institution" placeholder="请输入作者机构1" />
                  </Col>
                </Row>
                <Row :gutter="8">
                  <Col span="12">
                    <Input v-model="form.authors[1].name" placeholder="请输入通讯作者2" />
                  </Col>
                  <Col span="12">
                    <Input v-model="form.authors[1].institution" placeholder="请输入作者机构2" />
                  </Col>
                </Row>
              </FormItem>
              <FormItem label="发表年份">
                <Input v-model="form.publish_year" placeholder="请输入年份" />
              </FormItem>
              <FormItem label="语言类型">
                <Select v-model="form.language" placeholder="请选择语言类型">
                  <Option value="中文">中文</Option>
                  <Option value="外文">外文</Option>
                </Select>
              </FormItem>
              <FormItem label="原文链接" prop="source_url">
                <Input v-model="form.source_url" placeholder="请输入原文链接" />
              </FormItem>
              <FormItem label="期刊名">
                <Input v-model="form.journal" placeholder="请输入期刊名" />
              </FormItem>
              <FormItem label="样本量">
                <Input v-model="form.sample_size" placeholder="请输入样本量" />
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="影响因子">
                <Input v-model="form.impact_factor" placeholder="请输入影响因子" />
              </FormItem>
              <FormItem label="DOI">
                <Input v-model="form.doi" @on-change="handleDoiChange" placeholder="请输入DOI" />
                <div v-if="isDuplication" class="warning-wrap">DOI重复，请确定内容是否重复上传。</div>
              </FormItem>
              <FormItem label="地域信息">
                <!-- <Cascader v-model="regionValue" :data="continentList" :load-data="loadData" /> -->
                <RegionOption v-model="regionValue" />
              </FormItem>
              <FormItem label="相关疾病">
                <treeselect
                  placeholder="请选择"
                  noOptionsText="暂无数据"
                  :flat="true"
                  value-consists-of="ALL"
                  v-model="form.diseases"
                  :multiple="true"
                  :options="$store.state.zhiku.diseaseList"
                >
                </treeselect>
              </FormItem>
              <FormItem label="治疗">
                <treeselect
                  placeholder="请选择"
                  noOptionsText="暂无数据"
                  :flat="true"
                  value-consists-of="ALL"
                  v-model="form.treatments"
                  :multiple="true"
                  :options="$store.state.zhiku.drugList"
                >
                </treeselect>
              </FormItem>
              <FormItem label="医学方向">
                <treeselect
                  placeholder="请选择"
                  noOptionsText="暂无数据"
                  :flat="true"
                  value-consists-of="ALL"
                  v-model="form.medicals"
                  :multiple="true"
                  :options="$store.state.zhiku.medicalList"
                >
                </treeselect>
              </FormItem>
              <FormItem label="研究人群">
                <treeselect
                  placeholder="请选择"
                  noOptionsText="暂无数据"
                  :flat="true"
                  value-consists-of="ALL"
                  v-model="form.populations"
                  :multiple="true"
                  :options="$store.state.zhiku.researchList"
                >
                </treeselect>
              </FormItem>
              <FormItem label="研究类型">
                <treeselect
                  placeholder="请选择"
                  noOptionsText="暂无数据"
                  :flat="true"
                  value-consists-of="ALL"
                  v-model="form.research_type"
                  :multiple="true"
                  :options="$store.state.zhiku.researchTypeList"
                >
                </treeselect>
              </FormItem>
            </Col>
          </Row>
          <FormItem label="概述" prop="abstract">
            <Input v-model="form.abstract" type="textarea" :rows="3" placeholder="请输入概述" />
          </FormItem>
          <FormItem label="研究目的">
            <Input v-model="form.research_purpose" type="textarea" :rows="3" placeholder="请输入研究目的" />
          </FormItem>
          <FormItem label="研究方法">
            <Input v-model="form.research_method" type="textarea" :rows="3" placeholder="请输入研究方法" />
          </FormItem>
          <FormItem label="结果">
            <Input v-model="form.research_result" type="textarea" :rows="3" placeholder="请输入结果" />
          </FormItem>
          <FormItem label="结论">
            <Input v-model="form.research_conclusion" type="textarea" :rows="3" placeholder="请输入结论" />
          </FormItem>
          <FormItem label="生物标志物及检测" prop="biomarker_tags">
            <Table :columns="columns" :data="form.biomarker_tags">
              <template #biomarker="{ row, index }">
                <Select v-model="form.biomarker_tags[index].biomarker" transfer filterable allow-create>
                  <Option v-for="item in $store.state.zhiku.biomarkersList" :key="item.id" :value="item.id">{{
                    item.label
                  }}</Option>
                </Select>
              </template>
              <template #reagent="{ row, index }">
                <Select v-model="form.biomarker_tags[index].reagent" transfer filterable allow-create>
                  <Option v-for="item in $store.state.zhiku.reagentList" :key="item.id" :value="item.id">{{
                    item.label
                  }}</Option>
                </Select>
              </template>
              <template #instrument="{ row, index }">
                <Select v-model="form.biomarker_tags[index].instrument" transfer filterable allow-create>
                  <Option v-for="item in $store.state.zhiku.instrumentList" :key="item.id" :value="item.id">{{
                    item.label
                  }}</Option>
                </Select>
              </template>
              <template #action="{ row, index }">
                <Button size="small" ghost type="error" @click="biomarkerTagsDels(index)">删除</Button>
              </template>
            </Table>
            <Button style="margin-top: 16px;" type="dashed" icon="md-add" long @click="biomarkerTagsAdds">添加</Button>
          </FormItem>
          <FormItem label="要点">
            <Table :columns="columns1" :data="form.key_points">
              <template #data_type="{ row, index }">
                <Select v-model="form.key_points[index].data_type" transfer filterable allow-create>
                  <Option value="检验">检验</Option>
                  <Option value="临床">临床</Option>
                </Select>
              </template>
              <template #content="{ row, index }">
                <Input v-model="form.key_points[index].content" placeholder="请输入内容" />
              </template>
              <template #action="{ row, index }">
                <Button size="small" ghost type="error" @click="keyPointsDels(index)">删除</Button>
              </template>
            </Table>
            <Button style="margin-top: 16px;" type="dashed" icon="md-add" long @click="keyPointsAdds">添加</Button>
          </FormItem>
          <FormItem label="图片" prop="logo">
            <!-- <div class="logo-wrap" v-if="logoImg">
              <img :src="logoImg" alt="">
            </div> -->
            <Upload
              ref="upload"
              :show-upload-list="false"
              :format="['jpg', 'jpeg', 'png']"
              type="drag"
              action="-"
              :before-upload="handleUpload"
              style="display: inline-block;width:80px;"
            >
              <div class="upload-file">
                <Icon type="md-add" size="26" color="#B9C6D3"></Icon>
                <span>上传图片</span>
              </div>
            </Upload>
            <div class="file-list" v-for="(item, index) in form.images" :key="index">
              <div class="name require">
                <span>图片标题：</span>
                <Input v-model="form.images[index].name" style="width: 300px;" />
              </div>
              <div class="name" style="margin-left: 10px;">
                <span>注释：</span>
                <Input v-model="form.images[index].comment" style="width: 300px;" />
              </div>
              <Icon style="margin-left: 16px;" size="16" type="ios-close-circle" @click="handleFileDel(index)" />
            </div>
          </FormItem>
          <FormItem :label="'相关文献'">
            <Row>
              <Col span="18">
                <Select v-model="form.related_articles" transfer multiple filterable placeholder="请输入">
                  <Option v-for="item in articles" :key="item.id" :value="item.id">{{ item.label }}</Option>
                </Select>
              </Col>
            </Row>
          </FormItem>
        </Form>
      </div>
    </transition>
    <div slot="footer">
      <div style="display: flex;justify-content: flex-end;">
        <Button type="primary" ghost @click="addLabel">新增标签</Button>
        <Button @click="handleCancel">取消</Button>
        <Button type="primary" style="margin-left: 16px;" @click="handleOk">确定</Button>
      </div>
    </div>
    <Modal v-model="labelShow" title="新增标签">
      <div class="select-wrap"></div>
      <div class="select-wrap">
        <div class="label">主题生物标志物</div>
        <Select v-model="labelForm.biomarker" multiple @on-change="handleLabelChange">
          <Option v-for="item in $store.state.zhiku.biomarkers" :key="item.id" :value="item.id">{{
            item.label
          }}</Option>
        </Select>
      </div>
      <div class="select-wrap">
        <div class="label">标签类型</div>
        <Select v-model="labelForm.label_type" @on-change="handleLabelChange">
          <Option v-for="item in labelTypeList" :key="item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </div>
      <div class="select-wrap">
        <div class="label">标签等级</div>
        <div style="width: 100%">
          <RadioGroup v-model="isSingle">
            <Radio label="single">一级标签</Radio>
            <Radio label="complex">二级标签</Radio>
          </RadioGroup>
        </div>
      </div>
      <div class="select-wrap" v-if="isSingle !== 'single'">
        <div class="label">一级标签</div>
        <Select v-model="labelForm.parent" placeholder="请输入标签名称">
          <Option v-for="item in labelList" :key="item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </div>
      <div class="select-wrap">
        <div class="label">标签名称</div>
        <Input v-model="labelForm.name" placeholder="请输入标签名称" />
      </div>
      <div slot="footer">
        <div style="display: flex;justify-content: flex-end;">
          <Button @click="labelCancel">取消</Button>
          <Button type="primary" style="margin-left: 16px;" @click="labelOk">确定</Button>
        </div>
      </div>
    </Modal>
  </Modal>
</template>

<script>
import {
  Modal,
  Form,
  FormItem,
  Row,
  Col,
  Input,
  Select,
  Option,
  Upload,
  Icon,
  Button,
  Message,
  Table,
  RadioGroup,
  Radio
  // Cascader
} from 'view-design'
import {
  addLiterature,
  addTag,
  editLiterature,
  getLiteratureList,
  getTagList,
  getTagTypeList,
  checkDoi
  // getProvinceList,
  // getCountryList,
  // getContinentList
} from '@/api/zhiku'
import { uploadFile } from '@/api/meeting'
import { debounce, deepCopy } from '@/utils/tools'
import RegionOption from './regionOption.vue'

export default {
  components: {
    Modal,
    Form,
    FormItem,
    Row,
    Col,
    Input,
    Select,
    Option,
    Upload,
    Icon,
    Button,
    Table,
    RadioGroup,
    Radio,
    // Cascader,
    RegionOption
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    editorData: {
      type: Object,
      default: () => null
    },
    articleType: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      isSingle: 'single',
      labelList: [],
      labelTypeList: [],
      labelShow: false,
      labelForm: {
        biomarker: [],
        label_type: '',
        name: '',
        parent: ''
      },
      type: 'add',
      formRef: null,
      form: {
        biomarker: [],
        article_type: this.articleType,
        title: '',
        authors: [
          {
            name: '',
            institution: ''
          },
          {
            name: '',
            institution: ''
          }
        ],
        publish_year: null,
        language: '',
        source_url: '',
        journal: '',
        sample_size: '',
        impact_factor: null,
        doi: '',
        diseases: [],
        treatments: [],
        medicals: [],
        populations: [],
        abstract: '',
        biomarker_tags: [],
        key_points: [],
        images: [],
        related_articles: [],
        research_purpose: '',
        research_method: '',
        research_result: '',
        research_conclusion: '',
        research_type: [],
        continent: null,
        country: null,
        province: null
      },
      regionValue: [],
      ruleValidate: {
        biomarker: [{ required: true, type: 'array', message: '主题生物标志物不能为空', trigger: 'change' }],
        source_url: [{ required: true, message: '原文链接不能为空', trigger: 'change' }],
        abstract: [{ required: true, message: '概述不能为空', trigger: 'change' }]
        // biomarker_tags: [{ required: true, type: 'array', message: '生物标志物及检测不能为空' }]
      },
      columns: [
        { title: '生物标志物', key: 'biomarker', slot: 'biomarker' },
        { title: '试剂品牌', key: 'reagent', slot: 'reagent' },
        { title: '仪器厂家', key: 'instrument', slot: 'instrument' },
        { title: '操作', key: 'action', slot: 'action' }
      ],
      columns1: [
        { title: '要点类型', width: 160, key: 'data_type', slot: 'data_type' },
        { title: '内容', key: 'content', slot: 'content' },
        { title: '操作', width: 180, key: 'action', slot: 'action' }
      ],
      articles: [],
      isDuplication: false,
      continentList: []
    }
  },
  watch: {
    editorData: {
      handler(val) {
        if (val) {
          this.form = deepCopy({
            ...val,
            authors: val.authors.length ? val.authors : [
              {
                name: '',
                institution: ''
              },
              {
                name: '',
                institution: ''
              }
            ]
          })
          // 由于增加了transitions，所以需要在下一次DOM更新后再设置值
          this.$nextTick(() => {
            this.regionValue = [val.continent?.split(','), val.country?.split(','), val.province?.split(',')].filter(item => item)
          })
          this.type = 'edit'
          this.fetchArticles()
        }
      },
      deep: true
    },
    articleType(val) {
      this.form.article_type = val
    }
  },
  created() {
    this.handleDoiChange = debounce(this.handleDoiChange.bind(this)) // 将函数绑定到组件实例上
    // getContinentList().then(res => {
    //   if (res.code === 200) {
    //     console.log(res)
    //     this.continentList = res.data.map(item => ({
    //       ...item,
    //       label: item.name,
    //       value: item.name,
    //       children: [],
    //       loading: false
    //     }))
    //   }
    // })
  },
  mounted() {
    // this.fetchArticles()
    this.fetchLabels()
    this.fetchLabelsType()
    // this.init()
    this.$store.dispatch('zhiku/getBiomarkers')
  },
  methods: {
    init() {
      this.$store.dispatch('zhiku/getDiseaseList', { biomarker__id__in: this.form.biomarker.join(',') })
      this.$store.dispatch('zhiku/getDrugList', { biomarker__id__in: this.form.biomarker.join(',') })
      this.$store.dispatch('zhiku/getMedicalList', { biomarker__id__in: this.form.biomarker.join(',') })
      this.$store.dispatch('zhiku/getResearchList', { biomarker__id__in: this.form.biomarker.join(',') })
      this.$store.dispatch('zhiku/getBiomarkersList', { label_type: 2, biomarker__id__in: this.form.biomarker.join(',') })
      this.$store.dispatch('zhiku/getReagentList', { label_type: 6, biomarker__id__in: this.form.biomarker.join(',') })
      this.$store.dispatch('zhiku/getInstrumentList', { label_type: 7, biomarker__id__in: this.form.biomarker.join(',') })
      this.$store.dispatch('zhiku/getTypeList', { biomarker__id__in: this.form.biomarker.join(',') })
    },
    handleChange() {
      this.fetchArticles()
      this.init()
    },
    // loadData(item, callback) {
    //   item.loading = true
    //   if (item.value === 'China') {
    //     getProvinceList({ country: 'China' }).then(res => {
    //       item.loading = false
    //       item.children = res.data.map(item => ({
    //         label: item.name,
    //         value: item.name
    //       }))
    //       callback()
    //     })
    //     return
    //   }
    //   getCountryList({ continent: item.name }).then(res => {
    //     console.log(res)
    //     item.loading = false
    //     item.children = res.data.map(item => {
    //       if (item.name === 'China') {
    //         return {
    //           // label: ZhCountry[item.name] || item.name,
    //           label: item.name,
    //           value: item.name,
    //           loading: false,
    //           children: []
    //         }
    //       } else {
    //         return {
    //           // label: ZhCountry[item.name] || item.name,
    //           label: item.name,
    //           value: item.name
    //         }
    //       }
    //     })
    //     callback()
    //   })
    // },
    addLabel() {
      this.labelForm = {
        biomarker: [],
        label_type: null,
        name: null,
        parent: null
      }
      this.labelShow = true
    },
    labelCancel() {
      this.labelShow = false
    },
    labelOk() {
      if (this.labelForm.biomarker.length <= 0) {
        Message.error('请选择主题生物标志物')
        return
      }
      if (this.labelForm.label_type === '') {
        Message.error('请选择标签类型')
        return
      }
      if (this.isSingle !== 'single') {
        if (this.labelForm.name === '') {
          Message.error('请输入一级标签')
          return
        }
      }
      if (this.isSingle === 'single') {
        this.labelForm.parent = null
      }
      addTag(this.labelForm).then(res => {
        this.firstShow = false
        if (res.code === 200) {
          Message.success('添加成功')
          this.init()
          this.labelShow = false
        } else {
          Message.error(res.message)
        }
      })
    },
    resetFields() {
      this.$refs.formRef.resetFields()
      this.form = {
        biomarker: [],
        article_type: this.articleType,
        title: '',
        authors: [
          {
            name: '',
            institution: ''
          },
          {
            name: '',
            institution: ''
          }
        ],
        publish_year: null,
        language: '',
        source_url: '',
        journal: '',
        sample_size: '',
        impact_factor: null,
        doi: '',
        diseases: [],
        treatments: [],
        medicals: [],
        populations: [],
        abstract: '',
        biomarker_tags: [],
        key_points: [],
        images: [],
        related_articles: [],
        research_purpose: '',
        research_method: '',
        research_result: '',
        research_conclusion: ''
      }
      this.regionValue = []
    },
    fetchLabels() {
      getTagList({
        biomarker__id__in: this.labelForm.biomarker.join(','),
        label_type__in: this.labelForm.label_type,
        page_size: 999
      }).then(res => {
        if (res.code === 200) {
          this.labelList = res.data
        }
      })
    },
    fetchLabelsType() {
      getTagTypeList().then(res => {
        if (res.code === 200) {
          this.labelTypeList = res.data
        }
      })
    },
    fetchArticles() {
      getLiteratureList({
        biomarker__id__in: this.form.biomarker.join(','),
        page: this.page,
        page_size: 999
      }).then(res => {
        if (res.code === 200) {
          this.articles = res.data
            .map(item => ({
              ...item,
              label: item.title
            }))
            .filter(item => item.id !== this.editorData?.id)
          this.total = res.page.count
        }
      })
    },
    handleLabelChange() {
      this.fetchLabels()
    },
    biomarkerTagsAdds() {
      this.form.biomarker_tags.push({
        biomarker: null,
        instrument: null,
        reagent: null
      })
    },
    biomarkerTagsDels(index) {
      this.form.biomarker_tags.splice(index, 1)
    },
    handleDoiChange() {
      if (this.form.doi !== '') {
        checkDoi({ doi: this.form.doi }).then(res => {
          this.isDuplication = res.data.duplication
        })
      } else {
        this.idDuplication = false
      }
    },
    keyPointsAdds() {
      this.form.key_points.push({
        data_type: null,
        content: null
      })
    },
    keyPointsDels(index) {
      this.form.key_points.splice(index, 1)
    },
    handleAdd() {
      this.form.related_articles.push(null)
    },
    handleRemove(index) {
      this.form.related_articles.splice(index, 1)
    },
    handleVisible(val) {
      if (!val) {
        this.resetFields()
        this.$emit('close')
      }
      this.$emit('input', val)
    },
    handleUpload(file) {
      const formData = new FormData()
      formData.append('file', file)
      // formData.append('t', new Date().getTime())
      uploadFile(formData).then(res => {
        if (res.code === 200) {
          console.log(res)
          this.form.images.push({
            name: res.data.name,
            url: res.data.file,
            comment: ''
          })
        }
      })
      return false
    },
    handleFileDel(index) {
      this.form.images.splice(index, 1)
    },

    handleOk() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const regionOpt = {
            continent: this.regionValue[0] === undefined ? null : this.regionValue[0].join(','),
            country: this.regionValue[1] === undefined ? null : this.regionValue[1].join(','),
            province: this.regionValue[2] ? this.regionValue[2].join(',') : null
          }
          console.log(regionOpt)
          if (this.type === 'edit') {
            editLiterature(this.editorData.id, { ...this.form, ...regionOpt }).then(res => {
              if (res.code === 9) {
                Message.error(res.message)
                return
              }
              Message.success('修改成功')
              this.$emit('input', false)
              this.$emit('refresh')
            })
          } else {
            addLiterature({ ...this.form, ...regionOpt }).then(res => {
              if (res.code === 9) {
                Message.error(res.message)
                return
              }
              Message.success('添加成功')
              this.$emit('input', false)
              this.$emit('refresh')
              this.fetchArticles()
            })
          }
        }
      })
    },
    handleCancel() {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="less" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.form-wrap {
  max-height: 600px;
  overflow-y: scroll;
}
.upload-file {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > span {
    color: #b9c6d3;
    font-size: 14px;
    line-height: 14px;
    margin-top: 6px;
  }
}

.file-list {
  display: flex;
  align-items: center;
  cursor: pointer;

  .require {
    &::before {
      content: '*';
      display: inline-block;
      margin-right: 4px;
      line-height: 1;
      font-family: SimSun;
      font-size: 14px;
      color: #ed4014;
    }
  }

  .name {
    max-width: 600px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      color: #2d8cf0;
    }
  }
}

.select-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .label {
    font-size: 14px;
    color: #404e69;
    margin-right: 8px;
    white-space: nowrap;
    width: 130px;
    text-align: right;
  }
}
.warning-wrap {
  color: #fa795e;
}
</style>
