<template>
  <div class="meeting-container">
    <!-- 搜索 -->
    <div class="search-wrap">
      <div class="input-wrap">
        <Input v-model="search" placeholder="请输入关键词查询" size="large" :style="{width: '60%'}" />
        <Button type="primary" size="large" ghost style="margin-left: 16px;" @click="handleSearch">搜索</Button>
      </div>
      <Button type="primary" size="large" @click="handleModalClick">信息上传</Button>
    </div>
    <!-- 内容区 -->
    <div class="content-wrap">
      <div class="head-wrap">
        <div style="display: flex;">
          <Checkbox
            :indeterminate="indeterminate"
            :value="checkAll"
            @click.prevent.native="handleCheckAll">
            <div class="label-wrap">全选</div>
          </Checkbox>
          <div class="message-wrap">共 {{ total }} 条数据 <span v-if="checkAllGroup.length">，已选 {{ checkAllGroup.length }} 条</span></div>
        </div>
        <div class="del" v-if="checkAllGroup.length" @click="handleDels"><i class="iconfont iconshanchu" style="margin-right: 4px;"></i>批量删除</div>
      </div>
      <CheckboxGroup class="item-wrap" v-model="checkAllGroup" @on-change="checkAllGroupChange">
        <MeetingItem
          v-for="(item) in dataList"
          :key="item.id"
          :item="item"
          :activeIndex="activeIndex"
          @preview="handlePreview"
          @editor="handleEditor"
          @del="handleDelete"
          @more="handleMore(item.id)"
        />
      </CheckboxGroup>
      <div class="page-wrap" v-if="total !== 0">
        <Page :total="total" :page-size="5" show-elevator @on-change="handelPageChange" />
      </div>
    </div>
    <Modal
      v-model="modalShow"
      title="信息上传"
      width="1000"
      @on-visible-change="handelVisibleChange"
    >
      <div style="max-height: 630px; overflow-y: scroll;">
        <Form
          ref="formValidate"
          :model="formOption"
          :rules="ruleValidate"
          label-position="right"
          :label-width="80"
        >
          <FormItem label="会议名称" prop="title">
            <Input v-model="formOption.title"  placeholder="请输入会议名称"></Input>
          </FormItem>
          <FormItem label="会议标签" prop="meeting">
            <Select v-model="formOption.meeting" style="width: 300px;" filterable allow-create @on-create="handleCreateMeetingLable">
              <Option v-for="item in meetingList" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="领域" prop="field">
            <Select v-model="formOption.field" style="width: 300px;" filterable multiple allow-create @on-create="handleCreateFieldLable">
              <Option v-for="item in fieldList" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="会议年份" prop="meeting_year">
            <DatePicker v-model="formOption.meeting_year" @on-change="handleDateChange" type="year" placeholder="请选择年份" style="width: 200px"></DatePicker>
          </FormItem>
          <FormItem label="会议logo" prop="logo">
            <div class="logo-wrap" v-if="logoImg">
              <img :src="logoImg" alt="">
            </div>
            <Upload
              ref="upload"
              :show-upload-list="false"
              :format="['jpg','jpeg','png']"
              type="drag"
              action="-"
              :before-upload="handleLogo"
              style="display: inline-block;width:80px;"
              >
              <div class="upload-file">
                <Icon type="md-add" size="26" color="#B9C6D3"></Icon>
                <span>上传照片</span>
              </div>
            </Upload>
          </FormItem>
          <FormItem label="会议PDF" prop="file">
            <div class="pdf-wrap" v-if="formOption.file !== ''" @click="hanldlePreviewPdf(formOption.file)">{{ extractFilenameFromURL(formOption.file) }}</div>
            <Upload :show-upload-list="false" action="-" :before-upload="handleFile">
              <Button type="primary" ghost>点击上传</Button>
            </Upload>
          </FormItem>
          <FormItem label="题目" prop="topics">
            <QuestionTable :columns="columns" :data="formOption.topics">
              <template slot-scope="{ row, index }" slot="title">
                <div v-if="row.editor">
                  <Input v-model="formOption.topics[index].title" placeholder="请输入题目名称"></Input>
                </div>
                <div v-else>{{ row.title }}</div>
              </template>
              <template slot-scope="{ row, index }" slot="biomarker">
                <div v-if="row.editor">
                  <Select
                    v-model="formOption.topics[index].bio_marker"
                    transfer
                    filterable
                    multiple
                    allow-create
                    @on-create="(val) => {handleCreateBiomarkerLable(val, index)}"
                  >
                    <Option v-for="item in biomarkerList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                  </Select>
                </div>
                <div v-else>{{ idsToNames(row.bio_marker) }}</div>
              </template>
              <template slot-scope="{ row, index }" slot="pdf">
                <Upload v-if="row.editor" :show-upload-list="false" action="-" :before-upload="(e) => {
                  handleUpload(e, index)
                  return false
                }">
                  <span style="color: #2d8cf0;">{{ row.file === '' ? '点击上传' : extractFilenameFromURL(row.file) }}</span>
                </Upload>
                <div v-else>{{ extractFilenameFromURL(row.file) }}</div>
              </template>
              <template slot-scope="{ row, index }" slot="action">
                <div v-if="row.editor">
                  <Button class="primary-btn" size="small" type="default" @click="handelRowSave(index, row)">{{ isAdd ? '添加' : '保存' }}</Button>
                  <Button :class="isAdd ? 'delete-btn' : 'default-btn'" style="margin-left: 16px;" size="small" type="default" @click="handelRowCancel(index)">{{ isAdd ? '删除' : '取消' }}</Button>
                </div>
                <div v-else>
                  <Button :class="{'default-btn': !isRowEditor}" :disabled="isRowEditor" size="small" type="default" @click="handelRowEditor(index)">编辑</Button>
                  <Button :class="{'delete-btn': !isRowEditor}" :disabled="isRowEditor" style="margin-left: 16px;" size="small" type="default" @click="handelRowDelete(index)">删除</Button>
                </div>
              </template>
            </QuestionTable>
            <Button style="margin-top: 16px;" :disabled="isRowEditor" type="dashed" long @click="handelAddRow">添加标签</Button>
          </FormItem>
        </Form>
      </div>
      <div slot="footer">
        <div style="display: flex;justify-content: flex-end;">
          <Button @click="handelCancel">取消</Button>
          <Button type="primary" style="margin-left: 16px;" @click="handelOk">确定</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import {
  Input,
  Button,
  CheckboxGroup,
  Checkbox,
  Page,
  Modal,
  Form,
  FormItem,
  Upload,
  Icon,
  Select,
  Option,
  DatePicker,
  Table,
  Message
} from 'view-design'
import {
  fetchMeetingList,
  fetchMeetingField,
  fetchMeetingLabelList,
  fetchMeetingBiomarker,
  addMeeting,
  fetchMeetingDetail,
  updateMeeting,
  deleteMeeting,
  addMeetingLabelList,
  addMeetingField,
  addMeetingBiomarker,
  uploadFile,
  deleteMeetingList
} from '@/api/meeting/'
import MeetingItem from './meetingItem.vue'

const errorMap = {
  0: '请输入题目名称',
  1: '请选择生物标志物',
  2: '请上传PDF'
}
const base64Map = new Map([
  ['png', 'data:image/png;base64,'],
  ['jpg', 'data:image/jpg;base64,'],
  ['jpeg', 'data:image/jpeg;base64,'],
  ['gif', 'data:image/gif;base64,'],
  ['bmp', 'data:image/bmp;base64,']
])
export default {
  components: {
    Input,
    Button,
    CheckboxGroup,
    Checkbox,
    Page,
    Modal,
    Form,
    FormItem,
    Upload,
    Icon,
    Select,
    Option,
    DatePicker,
    MeetingItem,
    QuestionTable: Table
  },
  data () {
    return {
      indeterminate: false,
      checkAll: false,
      checkAllGroup: [],
      activeIndex: null,
      total: 0,
      page: 1,
      modalShow: false,
      search: '',
      modalType: '',
      isAdd: false,
      isRowEditor: false,
      dataList: [],
      formOption: {
        title: '',
        meeting: '',
        field: [],
        meeting_year: '',
        logo: '',
        file: '',
        topics: []
      },
      logoImg: null,
      ruleValidate: {
        title: [{ required: true, message: '请输入会议名称', trigger: 'blur' }],
        meeting: [{ required: true, type: 'number', message: '请选择会议标签', trigger: 'blur' }],
        field: [{ required: true, type: 'array', message: '请输入选择领域标签', trigger: 'blur' }],
        file: [{ required: true, message: '请上传文件', trigger: 'change' }],
        meeting_year: [{ required: true, type: 'date', message: '请选择年份', trigger: 'change' }],
        topics: [{ required: true, type: 'array', message: '请添加题目' }]
      },
      meetingList: [],
      fieldList: [],
      biomarkerList: [],
      columns: [
        // { title: '题目编号', slot: 'id' },
        { title: '题目名称', slot: 'title' },
        { title: '生物标志物', slot: 'biomarker' },
        { title: 'PDF', slot: 'pdf' },
        { title: '操作', slot: 'action' }
      ]
    }
  },
  created () {
    this.fetchList()
    fetchMeetingField({ t: new Date().getTime() }).then(res => {
      if (res.code === 200) {
        this.fieldList = res.data
      }
    })
    fetchMeetingBiomarker({ t: new Date().getTime() }).then(res => {
      if (res.code === 200) {
        this.biomarkerList = res.data
      }
    })
    fetchMeetingLabelList({ t: new Date().getTime() }).then(res => {
      if (res.code === 200) {
        this.meetingList = res.data
      }
    })
  },
  methods: {
    extractFilenameFromURL(url) {
      var urlParts = decodeURIComponent(url).split('/')
      var filename = urlParts[urlParts.length - 1]
      return filename
    },
    idsToNames (ids) {
      return ids.map(id => {
        const item = this.biomarkerList.find(item => item.id === id)
        return item ? item.name : ''
      }).join(',')
    },
    fetchList() {
      fetchMeetingList({
        page: this.page,
        page_size: 5,
        search: this.search,
        t: new Date().getTime()
      }).then(res => {
        if (res.code === 200) {
          this.dataList = res.data
          this.total = res.page.count
        }
      })
    },
    handleDels () {
      console.log('handleDels', this.checkAllGroup)
      deleteMeetingList({ ids: this.checkAllGroup }).then(res => {
        if (res.code === 200) {
          Message.success('删除成功')
          this.checkAllGroup = []
          this.fetchList()
        }
      })
    },
    handleSearch() {
      this.page = 1
      this.fetchList()
    },
    handleCheckAll () {
      if (this.indeterminate) {
        this.checkAll = false
      } else {
        this.checkAll = !this.checkAll
      }
      this.indeterminate = false

      if (this.checkAll) {
        this.checkAllGroup = this.dataList.map(item => item.id)
      } else {
        this.checkAllGroup = []
      }
    },
    checkAllGroupChange (data) {
      console.log('checkAllGroupChange', data)
      if (data.length === this.dataList.length) {
        this.indeterminate = false
        this.checkAll = true
      } else if (data.length > 0) {
        this.indeterminate = true
        this.checkAll = false
      } else {
        this.indeterminate = false
        this.checkAll = false
      }
    },
    handleModalClick () {
      this.modalType = 'add'
      this.modalShow = true
    },
    handelVisibleChange (val) {
      if (!val) {
        this.$refs.formValidate.resetFields()
        this.formOption.topics = []
        this.isAdd = false
        this.isRowEditor = false
        this.logoImg = null
      }
    },
    handleDateChange(date) {
      this.formOption.meeting_year = date
    },
    handleMore (index) {
      if (this.activeIndex === index) {
        this.activeIndex = null
      } else {
        this.activeIndex = index
      }
    },
    handleCreateMeetingLable (val) {
      this.meetingList.push({
        id: val,
        name: val
      })
      addMeetingLabelList({ name: val }).then(res => {
        if (res.code === 200) {
          this.meetingList[this.meetingList.length - 1].id = res.data.id
          this.formOption.meeting = res.data.id
        }
      })
    },
    handleCreateFieldLable (val) {
      this.fieldList.push({
        id: val,
        name: val
      })
      addMeetingField({ name: val }).then(res => {
        if (res.code === 200) {
          this.fieldList[this.fieldList.length - 1].id = res.data.id
          this.formOption.field.push(res.data.id)
        }
      })
    },
    handleCreateBiomarkerLable (val, index) {
      this.biomarkerList.push({
        id: val,
        name: val
      })
      addMeetingBiomarker({ name: val }).then(res => {
        if (res.code === 200) {
          this.biomarkerList[this.biomarkerList.length - 1].id = res.data.id
          this.formOption.topics[index].bio_marker.push(res.data.id)
        }
      })
    },
    handleBioMarkerChange (val, index) {
      this.formOption.topics[index].bio_marker = val
    },
    handleUpload(file, index) {
      const formData = new FormData()
      formData.append('file', file)
      // formData.append('t', new Date().getTime())
      uploadFile(formData).then(res => {
        if (res.code === 200) {
          this.formOption.topics[index].file = res.data.file
        }
      })
    },
    handleLogo(file) {
      const formData = new FormData()
      formData.append('file', file)
      // formData.append('t', new Date().getTime())
      uploadFile(formData).then(res => {
        if (res.code === 200) {
          this.formOption.logo = res.data.file
          this.logoImg = `${base64Map.get(res.data.file.split('.').pop())}${res.data.file_stream}`
        }
      })
      return false
    },
    handleFile(file) {
      // this.formOption.file = 'ullamco'
      const formData = new FormData()
      formData.append('file', file)
      // formData.append('t', new Date().getTime())
      uploadFile(formData).then(res => {
        if (res.code === 200) {
          this.formOption.file = res.data.file
        }
      })
      return false
    },
    hanldlePreviewPdf (url) {
      window.open(url)
    },
    handelAddRow () {
      this.isAdd = true
      this.isRowEditor = true
      this.formOption.topics.push({
        editor: true,
        title: '',
        bio_marker: '',
        file: ''
      })
    },
    handelRowDelete(index) {
      this.isRowEditor = false
      this.isAdd = false
      this.formOption.topics.splice(index, 1)
    },
    // 判断是否为空
    isEmpty(val) {
      if (typeof val === 'number') {
        return false
      }
      return val === null || !(Object.keys(val) || val).length
    },
    handelRowSave(index, data) {
      const arr = Object.keys(data)
        .filter(item => item !== 'editor' && item !== '_index' && item !== '_rowKey')
        .map(key => this.isEmpty(data[key]))
      if (arr.indexOf(true) === -1) {
        this.formOption.topics[index].editor = false
        this.isRowEditor = false
        this.isAdd = false
      } else {
        Message.warning(errorMap[arr.indexOf(true)])
      }
    },
    handelRowEditor(index) {
      this.formOption.topics[index].editor = true
      this.isRowEditor = true
    },
    handelRowCancel(index) {
      if (this.isAdd) {
        this.handelRowDelete(index)
      } else {
        this.formOption.topics[index].editor = false
        this.isRowEditor = false
      }
    },
    handelPageChange (val) {
      this.page = val
      this.fetchList()
    },
    handelOk () {
      this.$refs.formValidate.validate((valid) => {
        if (valid) {
          if (this.modalType === 'add') {
            addMeeting(this.formOption).then(res => {
              if (res.code === 200) {
                Message.success('新增成功')
                this.modalShow = false
                this.fetchList()
              }
            })
          } else {
            updateMeeting(this.formOption.id, {
              ...this.formOption,
              meeting_year: dayjs(this.formOption.meeting_year).format('YYYY')
            }).then(res => {
              if (res.code === 200) {
                Message.success('修改成功')
                this.modalShow = false
                this.fetchList()
              }
            })
          }
        } else {
          Message.error('请按要求填写信息')
        }
      })
    },
    handelCancel () {
      this.modalShow = false
    },
    handlePreview (data) {
      console.log('handlePreview', data)
    },
    handleEditor (data) {
      this.modalType = 'editor'
      this.modalShow = true
      fetchMeetingDetail(data.id, { t: new Date().getTime() }).then(res => {
        if (res.code === 200) {
          this.logoImg = res.data.logo_stream ? `${base64Map.get(res.data.logo.split('.').pop())}${res.data.logo_stream}` : null
          this.formOption = {
            ...res.data,
            meeting_year: res.data.meeting_year.toString(),
            field: res.data.field.map(field => field.id),
            topics: res.data.topics.map(topic => ({
              ...topic,
              bio_marker: topic.bio_marker.map(bio => bio.id),
              editor: false
            }))
          }
        }
      })
    },
    handleDelete (data) {
      deleteMeeting(data.id).then(res => {
        if (res.code === 200) {
          Message.success('删除成功')
          this.fetchList()
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
@import "./index.less";
</style>
