<template>
  <Modal :value="value" v-bind="$attrs" @on-visible-change="handleVisible">
    <transition name="fade">
      <Form ref="formRef" :model="form" label-position="right" :label-width="120" style="padding-right: 20px;">
        <FormItem label="主题名称">
          <Input placeholder="请输入" v-model="form.name" />
        </FormItem>
        <FormItem label="主题LOGO">
          <Upload
            :show-upload-list="false"
            action="-"
            accept=".jpg,.jpeg,.png"
            :format="['jpg', 'jpeg', 'png']"
            :before-upload="handleUpload"
          >
            <div>
              <Button type="primary"> 上传/更换图片 <Icon type="md-add"></Icon></Button>
            </div>
          </Upload>
        </FormItem>
        <div style="position: relative; margin-top: 20px" v-if="form.logo">
          <img
            style="margin-left: 120px;object-fit: contain; width: 200px; height: 200px"
            :src="form.logo_stream ? getUrl(form.logo_stream, form.logo) : form.logo"
            alt=""
          />
        </div>
        <FormItem label="移动端主题LOGO" :label-width="160">
          <Upload
            :show-upload-list="false"
            action="-"
            accept=".jpg,.jpeg,.png"
            :format="['jpg', 'jpeg', 'png']"
            :before-upload="handleMobileUpload"
          >
            <div>
              <Button type="primary"> 上传/更换图片 <Icon type="md-add"></Icon></Button>
            </div>
          </Upload>
        </FormItem>
        <div style="position: relative; margin-top: 20px" v-if="form.logo_h5">
          <img
            style="margin-left: 120px;object-fit: contain; width: 200px; height: 200px"
            :src="form.logo_h5_stream ? getUrl(form.logo_h5_stream, form.logo_h5) : form.logo_h5"
            alt=""
          />
        </div>
      </Form>
    </transition>
    <div slot="footer">
      <div style="display: flex;justify-content: flex-end;">
        <Button @click="handleCancel">取消</Button>
        <Button type="primary" style="margin-left: 16px;" @click="handleOk">确定</Button>
      </div>
    </div>
  </Modal>
</template>
<script>
import { Form, Message, Modal, Button, FormItem, Input, Icon, Upload } from 'view-design'
import { addObject, editObject, uploadLogo } from '@/api/zhiku'
import { deepCopy } from 'utils/tools'
const base64Map = new Map([
  ['png', 'data:image/png;base64,'],
  ['jpg', 'data:image/jpg;base64,'],
  ['jpeg', 'data:image/jpeg;base64,'],
  ['gif', 'data:image/gif;base64,'],
  ['bmp', 'data:image/bmp;base64,']
])
export default {
  name: 'uploadForm',
  components: {
    Form,
    Modal,
    Button,
    FormItem,
    Input,
    Icon,
    Upload
  },
  data() {
    return {
      form: {
        name: '',
        logo: ''
      }
    }
  },
  watch: {
    editorData: {
      handler(val) {
        if (val) {
          this.form = deepCopy(val)
        }
      },
      deep: true
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    editorData: {
      type: Object,
      default: () => null
    }
  },
  methods: {
    getUrl(stm, url) {
      return `${base64Map.get(url.split('.').pop())}${stm}`
    },
    handleVisible(val) {
      this.$emit('input', val)
    },
    handleUpload(file) {
      const formData = new FormData()
      formData.append('file', file)
      // formData.append('t', new Date().getTime())
      uploadLogo(formData).then(res => {
        if (res.code === 200) {
          this.form.logo = res.data.file
          this.form = { ...this.form }
          this.form.logo_stream = res.data.file_stream
        }
      })
      return false
    },
    handleMobileUpload(file) {
      const formData = new FormData()
      formData.append('file', file)
      // formData.append('t', new Date().getTime())
      uploadLogo(formData).then(res => {
        if (res.code === 200) {
          console.log(res)
          this.form.logo_h5 = res.data.file
          this.form = { ...this.form }
          this.form.logo_h5_stream = res.data.file_stream
        }
      })
      return false
    },
    handleOk() {
      this.$refs.formRef.validate(valid => {
        if (this.form.name && this.form.logo) {
          if (valid) {
            if (this.form.id) {
              editObject(this.form.id, {
                name: this.form.name,
                logo: this.form.logo,
                logo_h5: this.form.logo_h5
              }).then(res => {
                if (res.code === 200) {
                  Message.info('保存成功')
                  this.$emit('input', false)
                  this.$emit('refresh')
                } else {
                  Message.error(res.message)
                }
              })
            } else {
              addObject({
                name: this.form.name,
                logo: this.form.logo,
                logo_h5: this.form.logo_h5
              }).then(res => {
                if (res.code === 200) {
                  Message.info('保存成功')
                  this.$emit('input', false)
                  this.$emit('refresh')
                } else {
                  Message.error(res.message)
                }
              })
            }
          }
        } else {
          Message.info('主题名或主题logo不能为空')
        }
      })
    },
    handleCancel() {
      this.$emit('input', false)
    }
  }
}
</script>

<style scoped lang="less">
.upload-file {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > span {
    color: #b9c6d3;
    font-size: 14px;
    line-height: 14px;
    margin-top: 6px;
  }
}
</style>
