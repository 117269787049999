<template>
  <div class="role-manage">
    <div class="left">
      <div class="add-wrap">
        <div class="add-role" v-if="!add" @click="isAdd">+新增角色</div>
      </div>
      <div class="roles-lists">
        <div class="role-item" v-for="(item, index) in rolesLists" :key="item.id" :class="{'highlight' : current === index && !add}" @click="handleItem(item, index)">
          <div class="header">
            <div class="name">{{item.name}}</div>
            <div class="time">{{item.created_at}}</div>
          </div>
          <div class="desc">{{item.description}}</div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="role-config">
        <div class="config-item">
          <div class="label">角色基本配置</div>
          <div class="role-item">
            <div class="tag">角色名称</div>
            <div class="input" v-if="add">
              <Input v-model="addItem.name" maxlength="30" show-word-limit placeholder="请输入角色名称"/>
            </div>
            <template v-else>
              <div class="name" v-if="!editor">{{currentDetail.name}}</div>
              <div class="input" v-if="editor">
                <Input v-model="currentDetail.name" maxlength="30" show-word-limit/>
              </div>
            </template>
          </div>
          <div class="role-item">
            <div class="tag">角色描述</div>
            <div class="input" v-if="add">
              <Input v-model="addItem.description" maxlength="100" show-word-limit type="textarea" :rows="4" placeholder="请输入角色描述"/>
            </div>
            <template v-else>
              <div class="name desc" v-if="!editor">{{currentDetail.description}}</div>
              <div class="input" v-if="editor">
                <Input type="textarea" maxlength="100" show-word-limit :rows="4" v-model="currentDetail.description"/>
              </div>
            </template>
          </div>
        </div>
        <div class="config-item">
          <div class="label">角色权限</div>
          <div class="role-item">
            <div class="tag">通用权限</div>
            <div class="name">文献库+我的图书馆+个人中心</div>
          </div>
          <div class="role-item">
            <div class="tag">配置权限</div>
            <div class="input check" v-if="add">
              <Checkbox v-model="addItem.has_analysis">
                <span style="margin-left: 12px">热搜分析</span>
              </Checkbox>
            </div>
            <template v-else>
              <div class="name" v-if="!editor">{{configAuthInfo}}</div>
              <div class="input check" v-if="editor">
                <Checkbox v-model="currentDetail.has_analysis">
                  <span style="margin-left: 12px">热搜分析</span>
                </Checkbox>
              </div>
            </template>
          </div>
        </div>
        <div class="edit">
          <template v-if="editor || add">
            <Button type="default" style="margin-right: 8px;color: #6B8299" @click="isCancel">取消</Button>
            <Button type="primary" @click="isSave">保存</Button>
          </template>
          <template v-else>
            <Button type="primary" @click="isEditor">编辑</Button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, Input, Checkbox, Message } from 'view-design'
import { addItem } from './config'
import cloneDeep from 'lodash/cloneDeep'
import { fetchSystemRoleList, fetchSystemPutRole, fetchSystemAddRole } from '@/api/'
import { deepCopy } from '@/utils/tools'
import dayjs from 'dayjs'
export default {
  name: 'RoleManage',
  data () {
    return {
      rolesLists: [],
      currentEdit: {},
      addItem,
      editor: false,
      add: false,
      current: 0,
      currentDetail: {}
    }
  },

  components: {
    Button,
    Input,
    Checkbox
  },

  computed: {
    configAuthInfo({ currentDetail }) {
      const { has_analysis } = currentDetail
      return has_analysis ? '热搜分析' : '无'
    }
  },

  created() {
    this.fetchRoles()
  },

  methods: {
    fetchRoles() {
      fetchSystemRoleList().then(res => {
        this.rolesLists = deepCopy(res.data.map(v => ({
          ...v,
          created_at: dayjs(v.created_at).format('YYYY-MM-DD  HH:mm:ss')
        })))
        this.currentDetail = deepCopy(res.data[this.current])
      })
    },
    handleItem(item, key) {
      this.current = key
      this.currentDetail = deepCopy(item)
    },
    resetData() {
      this.addItem = {
        name: '',
        description: '',
        has_analysis: false
      }
    },
    isEditor() {
      this.editor = true
      this.add = false
    },
    isAdd() {
      if (this.rolesLists.length >= 5) {
        Message.warning({
          content: '角色最多添加五个！',
          duration: 2
        })
      } else {
        this.add = true
        this.editor = false
        this.resetData()
      }
    },
    isCancel() {
      if (this.editor) {
        this.editor = false
      } else if (this.add) {
        this.add = false
        this.addItem = cloneDeep(addItem)
      }
    },
    async isSave() {
      if (this.add) {
        if (!this.addItem.name.trim() || !this.addItem.description.trim()) {
          Message.warning({
            content: '名称或描述不能为空！',
            duration: 2
          })
        } else {
          const res = await fetchSystemAddRole(this.addItem)
          const { data, message } = res
          if (!data) {
            Message.warning({
              content: message,
              duration: 2
            })
          } else {
            this.add = false
            this.resetData()
            this.fetchRoles()
          }
          // Message.success('添加成功')
        }
      } else {
        if (!this.currentDetail.name.trim() || !this.currentDetail.description) {
          Message.warning({
            content: '名称或描述不能为空！',
            duration: 2
          })
        } else {
          fetchSystemPutRole(this.currentDetail.id, {
            name: this.currentDetail.name,
            description: this.currentDetail.description,
            has_analysis: this.currentDetail.has_analysis
          }).then(res => {
            const { data, message } = res
            if (!data) {
              Message.warning({
                content: message,
                duration: 2
              })
            } else {
              Message.success('修改成功！')
              this.editor = false
              this.fetchRoles()
            }
          })
        }
      }
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>