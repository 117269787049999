<template>
  <div class="feed-item">
    <div class="item-header">
      <Checkbox :label="item.id">
        <span class="item-time">
          <span>{{item.created_time}}</span>
        </span>
      </Checkbox>
    </div>
    <div class="item-info">
      <div class="info-contact">
        <div class="info">
          账号：<span>{{item.user_info.username}}</span>
        </div>
      </div>
      <div class="info-content">
        内容：
        <span class="content" ref="content">
          <span class="detail" :class="{'ellipsis': isMore && !item.isMore}">{{item.content}}</span>
          <span class="more" @click="isToggleMore" v-if="isMore">
            More
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox } from 'view-design'
export default {
  name: 'FeedItem',
  data() {
    return {
      isMore: false
    }
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    itemKey: {
      type: Number
    }
  },

  components: {
    Checkbox
  },

  watch: {
    item: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            if (this.$refs.content.getBoundingClientRect().height > 21) {
              this.isMore = true
            } else {
              this.isMore = false
            }
          })
        }
      },
      immediate: true
    }
  },

  computed: {},

  methods: {
    isToggleMore() {
      this.$emit('more', this.itemKey)
    }
  }
}

</script>
<style lang='less'>
@import url('./index.less');
</style>