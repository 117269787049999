<template>
  <div class="guide-upload-wrap">
    <div class="search-wrap">
      <div class="left">
        <Input v-model="search" size="large" placeholder="输入关键词查询" />
        <Button size="large"  style="margin-left: 24px;" @click="handelSearch">搜索</Button>
      </div>
      <div class="right">
        <Button size="large" @click="handelShowModal">单条上传</Button>
        <Button size="large"  style="margin-left: 16px;" type="primary" @click="() => batchShow = true">批量上传</Button>
      </div>
    </div>
    <div class="content-wrap">
      <div class="content-header-wrap" v-if="total !== 0">
        <div style="display: flex;">
          <Checkbox
            :indeterminate="indeterminate"
            :value="checkAll"
            @click.prevent.native="handleCheckAll">
            <div class="label-wrap">全选</div>
          </Checkbox>
          <div class="message-wrap">共 {{ total }} 条数据 <span v-if="checkAllGroup.length">，已选 {{ checkAllGroup.length }} 条</span></div>
        </div>
        <Button v-if="checkAllGroup.length" type="error" size="large" @click="handelBatchDelete">批量删除</Button>
      </div>
      <CheckboxGroup class="item-wrap" v-model="checkAllGroup" @on-change="checkAllGroupChange">
        <LiteratureItem
          v-for="item in dataList"
          :key="item.id"
          :item="item"
          @on-editor="handelEditorClick"
          @on-delete="handelDeleteClick"
          @on-view="handelView"
        />
      </CheckboxGroup>
      <div class="empty-wrap" v-if="total === 0">
        <img src="@/assets/imgs/empty.png" alt="/">
      </div>
      <div class="page-wrap" v-if="total !== 0">
        <Page :total="total" show-elevator @on-change="handelPageChange" />
      </div>
    </div>
    <Spin style="position: fixed;" size="large" fix v-if="spinShow">
      <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
      <div>Loading</div>
    </Spin>
    <Modal
      class="single-model"
      v-model="singleShow"
      title="手动上传"
      width="1000"
      @on-visible-change="handelVisibleChange"
    >
      <transition name="fade">
        <SingleUpload
          v-if="singleShow"
          :isView="isView"
          :typeList="literatureTypes"
          :editorData="editorData"
          :viewData="viewData"
          @on-submit="handelSubmit"
          @on-cancel="handelCancel"
          @on-editor="handelEditor"
        />
      </transition>
      <div slot="footer"></div>
    </Modal>
    <Modal
      class="batch-modal"
      v-model="batchShow"
      title="批量上传"
    >
      <transition name="fade">
        <batchUpload v-if="batchShow" @on-submit="handelBatchSubmit" />
      </transition>
      <div slot="footer"></div>
    </Modal>
  </div>
</template>

<script>
import { Input, Button, CheckboxGroup, Checkbox, Page, Modal, Icon, Message, Spin } from 'view-design'
import SingleUpload from './singleUpload.vue'
import batchUpload from './batchUpload.vue'
import LiteratureItem from './literatureItem.vue'
import {
  fetchLiteratureType,
  submitGuideContent,
  getUploads,
  getUploadDataDetail,
  fetchBiomarkers,
  fetchDisease,
  deleteUploadData,
  putUploadData,
  submitLiterature,
  deleteUploadsData
} from '@/api/upload/'
import dayjs from 'dayjs'

export default {
  components: {
    Input,
    Button,
    CheckboxGroup,
    Checkbox,
    Page,
    Modal,
    SingleUpload,
    batchUpload,
    Icon,
    Spin,
    LiteratureItem
  },
  data() {
    return {
      indeterminate: false,
      checkAll: false,
      checkAllGroup: [],
      dataList: [],
      singleShow: false,
      batchShow: false,
      literatureTypes: [],
      page: 1,
      total: 0,
      editorData: null,
      viewData: null,
      spinShow: false,
      search: '',
      isView: false
    }
  },
  created() {
    fetchLiteratureType().then(res => {
      if (res.code === 200) {
        this.literatureTypes = res.data
      }
    })
    this.fetchDataList()
  },
  methods: {
    fetchDataList() {
      this.spinShow = true
      getUploads({
        page: this.page,
        bio_markers: this.search,
        t: new Date().getTime()
      }).then(res => {
        console.log(res)
        this.spinShow = false
        if (res.code === 200) {
          this.dataList = res.data.data
          this.total = res.data.page.count
        }
      })
    },
    handelSearch() {
      this.fetchDataList()
    },
    handelVisibleChange(visible) {
      if (!visible) {
        this.editorData = null
        this.viewData = null
        this.isView = false
      }
    },
    handelShowModal() {
      this.singleShow = true
    },
    handelSubmit(data, type) {
      console.log(data)
      if (type === 'guide') {
        submitGuideContent(data).then(res => {
          if (res.code === 200) {
            this.singleShow = false
            Message.success('上传成功!')
            this.fetchDataList()
          }
          if (res.code === 9) {
            Message.error(res.message)
          }
        })
      } else {
        submitLiterature(data).then(res => {
          if (res.code === 200) {
            this.singleShow = false
            Message.success('上传成功!')
            this.fetchDataList()
          }
        })
      }
    },
    // 批量删除
    handelBatchDelete() {
      if (this.checkAllGroup.length) {
        deleteUploadsData({ ids: this.checkAllGroup }).then(res => {
          Message.success('删除成功')
          this.checkAllGroup = []
          this.fetchDataList()
        }).catch(err => {
          Message.error(err.message)
        })
      }
    },
    // 批量上传
    handelBatchSubmit() {
      this.batchShow = false
      this.fetchDataList()
    },
    handelEditor(data) {
      console.log(data, 'editor')
      putUploadData(data.id, data).then(res => {
        console.log(res)
        if (res.code === 200) {
          this.singleShow = false
          Message.success('修改成功!')
          this.fetchDataList()
        }
      })
    },
    handelCancel() {
      this.singleShow = false
    },
    handleCheckAll () {
      if (this.indeterminate) {
        this.checkAll = false
      } else {
        this.checkAll = !this.checkAll
      }
      this.indeterminate = false

      if (this.checkAll) {
        this.checkAllGroup = this.dataList.map(item => item.id)
      } else {
        this.checkAllGroup = []
      }
    },
    checkAllGroupChange (data) {
      if (data.length === this.dataList.length) {
        this.indeterminate = false
        this.checkAll = true
      } else if (data.length > 0) {
        this.indeterminate = true
        this.checkAll = false
      } else {
        this.indeterminate = false
        this.checkAll = false
      }
    },
    handelPageChange(page) {
      this.page = page
      this.fetchDataList()
    },
    formatLabelData(values, option) {
      const arr = []
      option.forEach(item => {
        values.indexOf(item.id) !== -1 && arr.push(item.name)
      })
      return arr.join(',')
    },
    promiseData(arr) {
      return new Promise((resolve, reject) => {
        if (arr.length) {
          Promise.all(
            arr.map(async (item) => {
              const res = await Promise.all([fetchBiomarkers({
                fields: item.field.join(',')
              }), fetchDisease({
                field_id__in: item.field.join(',')
              })])
              return {
                bio_markers: this.formatLabelData(item.bio_markers, res[0].data),
                disease: this.formatLabelData(item.disease, res[1].data)
              }
            })
          ).then(res => {
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        }
      })
    },
    // 编辑
    handelEditorClick(item) {
      getUploadDataDetail(item.id).then(async (res) => {
        const arr = await this.promiseData(res.data.labels)
        this.editorData = {
          ...res.data,
          publish_year: dayjs(res.data.publish_year).format(),
          labels: res.data.labels.map(item => ({
            ...item,
            editor: false
          })),
          labelsText: arr
        }
        console.log(this.editorData, 'index')
        this.singleShow = true
      })
    },
    handelDeleteClick(item) {
      console.log(item, 'de')
      deleteUploadData(item.id).then(res => {
        console.log(res)
        if (res.code === 200) {
          this.fetchDataList()
          Message.success('删除成功!')
        }
      })
    },
    handelView(item) {
      this.isView = true
      getUploadDataDetail(item.id).then(async (res) => {
        const arr = await this.promiseData(res.data.labels)
        this.viewData = {
          ...res.data,
          publish_year: dayjs(res.data.publish_year).format(),
          labels: res.data.labels.map(item => ({
            ...item,
            editor: false
          })),
          labelsText: arr
        }
        console.log(this.viewData, 'index')
        this.singleShow = true
      })
    }
  }
}
</script>

<style>
.demo-spin-icon-load{
  animation: ani-demo-spin 1s linear infinite;
}
</style>

<style lang='less' scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.guide-upload-wrap {
  .search-wrap {
    padding: 24px 40px;
    border-radius: 0 0 12px 12px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;

    .left {
      width: 54%;
      display: flex;
    }

    .right {
      display: flex;
    }
  }
  .content-wrap {
    box-shadow: 0px 2px 8px 0px rgba(210,210,210,0.49);
    border-radius: 12px;
    margin-top: 24px;
    background-color: #fff;

    .content-header-wrap {
      width: 100%;
      padding: 26px 40px 23px 40px;
      border-bottom: 1px solid #E8ECEF;
      display: flex;
      align-items: center;
      justify-content: space-between;

      // & /deep/ .ivu-checkbox-wrapper {
      //   display: flex;
      // }

      .label-wrap {
        margin-left: 8px;
        display: inline-block;
        user-select: none;
      }

      .message-wrap {
        font-size: 14px;
        color: #656E77;
        margin-left: 12px;
      }
    }

    .item-wrap {
      padding: 0 40px 20px 40px;
      .guide-item-wrap {
        display: flex;
        margin-top: 30px;
        position: relative;
        .check-item-wrap {
          display: inline-block;
          margin-left: 24px;
          .title {
            font-size: 18px;
            font-weight: bold;
            color: #202327;
            margin-bottom: 24px;
            line-height: 1em;
          }

          .bottom-item {
            display: flex;
          }
        }

        .btn-wrap {
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -8px;
          display: flex;

          .btn {
            font-size: 16px;
            color: #007FFF;
            cursor: pointer;
            user-select: none;
            margin-right: 48px;
          }

          .delete {
            font-size: 16px;
            color: #FF5252;
          }
        }
      }
    }

    .field-item {
      display: flex;
      margin-bottom: 12px;
      .label {
        font-size: 14px;
        color: #8E9CA9;
      }

      .value {
        font-size: 14px;
        color: #494E53;
      }
    }
    .page-wrap {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 20px;
      padding-right: 16px;
    }
  }
}
.single-model {
  & /deep/ .ivu-modal-content > .ivu-modal-footer {
    border-top: none;
    padding: 0;
  }

  & /deep/ .ivu-modal-body {
    padding: 0;
  }
}

.batch-modal {
  & /deep/ .ivu-modal-footer {
    border: none;
    padding: 0;
  }

  & /deep/ .ivu-modal-body {
    padding: 0;
  }
}

.empty-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;

  & > img {
    width: 160px;
    height: 160px;
  }
}
</style>
