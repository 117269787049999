<template>
  <div class="label-page-wrap">
    <div class="left-wrap">
      <div class="name">模块名称</div>
      <div
        class="item-wrap"
        :class="{ 'item-active': item.value === current }"
        v-for="item in moduleList"
        :key="item.value"
        @click="handleCurrentClick(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="right-wrap">
      <!-- 会议速递 -->
      <div v-if="current === '2'">
        <div class="head-wrap">
          <div class="select-wrap">
            <div class="label">标签类型</div>
            <Select v-model="labelType" style="width: 160px;" @on-change="handleSelectChange">
              <Option :value="-1">全部</Option>
              <Option v-for="item in labelTyps" :key="item.id" :value="item.id">{{ item.name }}</Option>
            </Select>
          </div>
          <Button type="primary" ghost @click="handleAddClick">新增</Button>
        </div>
        <LabelTable :columns="columns" :data="dataList" :loading="loading">
          <template slot-scope="{ row }" slot="action">
            <Button type="primary" size="small" ghost style="margin-right: 8px;" @click="handleEditor(row)"
              >编辑</Button
            >
            <Button type="error" size="small" ghost @click="handleDel(row.id)">删除</Button>
          </template>
        </LabelTable>
        <div style="display: flex;justify-content: flex-end;margin-top: 24px;">
          <Page :current="page" :total="total" @on-change="handlePageChange" />
        </div>
      </div>
      <!-- 智库 -->
      <div class="zhiku-wrap" v-if="current === '1'">
        <div class="zhiku-select-wrap">
          <div style="display: flex;">
            <div class="item-wrap">
              <div class="label">主题生物标志物</div>
              <Select v-model="biomarker_id__in" clearable style="width: 200px;" @on-change="handleZhikuSelectChange">
                <Option v-for="item in biomarkerList" :key="item.id" :value="item.id">{{ item.name }}</Option>
              </Select>
            </div>
            <div class="item-wrap">
              <div class="label">标签类型</div>
              <Select v-model="label_type__in" clearable style="width: 120px;" @on-change="handleZhikuSelectChange">
                <Option v-for="item in labelList" :key="item.id" :value="item.id">{{ item.name }}</Option>
              </Select>
            </div>
          </div>
          <Button type="primary" ghost @click="handleFirstAdd">新增</Button>
        </div>
        <LabelTable :columns="firstColumns" :data="firstDataList" :loading="loading">
          <template slot-scope="{ row }" slot="action">
            <Button type="primary" size="small" ghost style="margin-right: 8px;" @click="handleShowSecond(row)"
              >二级标签</Button
            >
            <Button type="primary" size="small" ghost style="margin-right: 8px;" @click="handleFirstEditor(row)"
              >编辑</Button
            >
            <Button type="error" size="small" ghost @click="handleFirstDel(row.id)">删除</Button>
          </template>
        </LabelTable>
        <div style="display: flex;justify-content: flex-end;margin-top: 24px;">
          <Page :current="zhikuPage" :total="zhikuTotal" @on-change="handleZhikuPageChange" />
        </div>
      </div>
    </div>
    <!-- 智库 end -->
    <Modal v-model="showModal" title="新增标签" @on-visible-change="handleVisibleChange">
      <div class="select-wrap">
        <div class="label">标签类型</div>
        <Select v-model="addForm.label_type">
          <Option v-for="item in labelTyps" :key="item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </div>
      <div class="select-wrap">
        <div class="label">标签名称</div>
        <Input v-model="addForm.name" placeholder="请输入标签名称" />
      </div>
      <div slot="footer">
        <div style="display: flex;justify-content: flex-end;">
          <Button @click="cancel">取消</Button>
          <Button type="primary" style="margin-left: 16px;" @click="ok">确定</Button>
        </div>
      </div>
    </Modal>
    <Modal v-model="firstShow" title="一级标签" @on-visible-change="handleFirstChange">
      <div class="select-wrap">
        <div class="label">主题生物标志物</div>
        <Select v-model="addFirstForm.biomarker" multiple style="width: 360px">
          <Option v-for="item in biomarkerList" :key="item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </div>
      <div class="select-wrap">
        <div class="label">标签类型</div>
        <Select v-model="addFirstForm.label_type" style="width: 360px">
          <Option v-for="item in labelList" :key="item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </div>
      <div class="select-wrap">
        <div class="label">一级标签</div>
        <Input v-model="addFirstForm.name" placeholder="请输入标签名称" style="width: 360px" />
      </div>
      <div slot="footer">
        <div style="display: flex;justify-content: flex-end;">
          <Button @click="handleFirstCancel">取消</Button>
          <Button type="primary" style="margin-left: 16px;" @click="handleFirstOk">确定</Button>
        </div>
      </div>
    </Modal>
    <Modal v-model="secondShow" title="二级标签" footer-hide class-name="vertical-center-modal">
      <transition name="fade">
        <div v-if="secondShow" style="max-height: 600px; overflow-y: auto;">
          <FormTable
            ref="formTable"
            :columns="secondColumns"
            :data="secondDataList"
            :isAsync="true"
            @add="handleRowAdd"
            @save="handleRowSave"
            @delete="handleRowDelete"
          >
            <template #name="{ row, index }">
              <div v-if="row.editor">
                <Input v-model="secondDataList[index].name" placeholder="请输入标签名称" />
              </div>
              <div v-else>{{ row.name }}</div>
            </template>
          </FormTable>
        </div>
      </transition>
    </Modal>
  </div>
</template>

<script>
import { Select, Option, Table, Button, Modal, Input, Page, Message } from 'view-design'
import FormTable from '@/components/FormTable'
import {
  fetchMeetingLabelType,
  fetchMeetingLabel,
  addMeetingLabel,
  deleteMeetingLabel,
  updateMeetingLabel
} from '@/api/meeting'
import { getObjectList, getTagTypeList, getTagList, deleteTag, addTag, editTag } from '@/api/zhiku'
export default {
  components: {
    Select,
    Option,
    LabelTable: Table,
    Button,
    Modal,
    Input,
    Page,
    FormTable
  },
  data() {
    return {
      moduleList: [
        { name: '智库', value: '1' },
        { name: '会议速递', value: '2' }
      ],
      current: '1',
      total: 0,
      page: 1,
      loading: false,
      showModal: false,
      firstShow: false,
      secondShow: false,
      labelTyps: [],
      labelType: -1,
      formType: '',
      addForm: {
        label_type: '',
        name: ''
      },
      editorId: '',
      columns: [
        {
          title: '序号',
          type: 'index',
          width: 120,
          align: 'center'
        },
        {
          title: '标签类型',
          key: 'label_type',
          render: (h, params) => {
            return h(
              'div',
              this.labelTyps.find(item => item.id === params.row.label_type)
                ? this.labelTyps.find(item => item.id === params.row.label_type).name
                : '未知类型'
            )
          }
        },
        {
          title: '标签名称',
          key: 'name'
        },
        {
          title: '操作',
          slot: 'action',
          width: 200
        }
      ],
      dataList: [],
      firstColumns: [
        { title: '序号', type: 'index', width: 120, align: 'center' },
        {
          title: '主题生物标志物',
          key: 'biomarker',
          render: (h, params) => {
            const { row } = params
            return h('div', {}, row.biomarker.map(item => item.name).join(','))
          }
        },
        { title: '标签类型', key: 'label_type' },
        { title: '一级标签', key: 'name' },
        { title: '操作', slot: 'action', width: 260 }
      ],
      secondColumns: [
        { title: '二级标签', key: 'name', slot: 'name' },
        { title: '操作', slot: 'action', width: 160 }
      ],
      firstDataList: [],
      secondDataList: [],
      biomarkerList: [],
      labelList: [],
      zhikuTotal: 0,
      zhikuPage: 1,
      biomarker_id__in: '',
      label_type__in: '',
      addFirstForm: {
        biomarker: [],
        label_type: '',
        name: ''
      },
      secondForm: {
        biomarker: '',
        label_type: '',
        name: '',
        parent: null
      }
    }
  },
  watch: {
    labelLevel(val) {
      console.log(val)
    },
    current: {
      handler(val) {
        if (val === '1') {
          console.log('智库')
          getObjectList({
            page_size: 999
          }).then(res => {
            if (res.code === 200) {
              this.biomarkerList = res.data
            }
          })
          getTagTypeList().then(res => {
            if (res.code === 200) {
              this.labelList = res.data
            }
          })
          this.fetchZhikuList()
        } else {
          fetchMeetingLabelType().then(res => {
            if (res.code === 200) {
              this.labelTyps = res.data
            }
          })
          this.fetchList()
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchList() {
      this.loading = true
      fetchMeetingLabel({
        page: this.page,
        label_type: this.labelType === -1 ? '' : this.labelType,
        t: new Date().getTime()
      }).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.dataList = res.data
          this.total = res.page.count
        }
      })
    },
    fetchZhikuList() {
      getTagList({
        page: this.zhikuPage,
        biomarker__id__in: this.biomarker_id__in,
        label_type__in: this.label_type__in,
        t: new Date().getTime()
      }).then(res => {
        console.log(res)
        if (res.code === 200) {
          this.firstDataList = res.data.map(item => ({
            ...item,
            secondList: item.children,
            label_type_id: this.labelList.find(label => label.name === item.label_type)
              ? this.labelList.find(label => label.name === item.label_type).id
              : null,
            biomarker_id: item.biomarker.map(citem => citem.id),
            children: []
          }))
          this.zhikuTotal = res.page.count
        }
      })
    },
    handleCurrentClick(item) {
      this.current = item.value
    },
    handleSelectChange() {
      this.page = 1
      this.fetchList()
    },
    handleAddClick() {
      this.showModal = true
      this.formType = 'add'
    },
    handlePageChange(page) {
      this.page = page
      this.fetchList()
    },
    handleVisibleChange(visible) {
      if (!visible) {
        this.addForm = {
          label_type: '',
          name: ''
        }
        this.formType = ''
      }
    },
    ok() {
      if (this.formType === 'add') {
        this.add()
      } else {
        this.editor()
      }
    },
    add() {
      if (this.addForm.label_type === '') {
        Message.error('请选择标签类型')
        return
      }
      if (this.addForm.name === '') {
        Message.error('请输入标签名称')
        return
      }
      addMeetingLabel(this.addForm).then(res => {
        if (res.code === 200) {
          this.showModal = false
          this.fetchList()
        } else {
          Message.error(res.message.indexOf('unique') > -1 ? '不能重复添加标签' : res.message)
        }
      })
    },
    editor() {
      updateMeetingLabel(this.editorId, this.addForm).then(res => {
        if (res.code === 200) {
          this.showModal = false
          this.fetchList()
        }
      })
    },
    cancel() {
      this.showModal = false
    },
    handleEditor(row) {
      this.showModal = true
      this.formType = 'editor'
      this.addForm = {
        label_type: row.label_type,
        name: row.name
      }
      this.editorId = row.id
    },
    handleDel(id) {
      deleteMeetingLabel(id).then(res => {
        if (res.code === 200) {
          Message.success('删除成功')
          this.fetchList()
        } else {
          Message.error(res.message.indexOf('invalid') > -1 ? '标签已被使用，不能删除' : res.message)
        }
      })
    },
    handleFirstChange(visible) {
      if (!visible) {
        this.addFirstForm = {
          biomarker: [],
          label_type: '',
          name: ''
        }
        this.formType = ''
      }
    },
    handleZhikuPageChange(page) {
      this.zhikuPage = page
      this.fetchZhikuList()
    },
    handleZhikuSelectChange() {
      this.zhikuPage = 1
      this.fetchZhikuList()
    },
    handleFirstAdd() {
      this.firstShow = true
      this.formType = 'add'
    },
    handleFirstCancel() {
      this.firstShow = false
    },
    handleFirstOk() {
      console.log(this.addFirstForm)
      const regEmpty = /^[ ]*$/
      if (this.addFirstForm.biomarker.length <= 0) {
        Message.error('请选择主题生物标志物')
        return
      }
      // label_type可能为undefined
      if (!this.addFirstForm.label_type || regEmpty.test(this.addFirstForm.label_type)) {
        Message.error('请选择标签类型')
        return
      }
      if (regEmpty.test(this.addFirstForm.name)) {
        Message.error('请输入一级标签')
        return
      }
      if (this.formType === 'add') {
        addTag({
          ...this.addFirstForm,
          parent: null
        }).then(res => {
          if (res.code === 200) {
            Message.success('添加成功')
            this.firstShow = false
            this.fetchZhikuList()
          } else {
            Message.error(res.message)
          }
        })
      } else {
        editTag(this.addFirstForm.id, this.addFirstForm).then(res => {
          if (res.code === 200) {
            this.firstShow = false
            Message.success('编辑成功')
            this.fetchZhikuList()
          } else {
            Message.error(res.message)
          }
        })
      }
    },
    handleShowSecond(row) {
      this.secondDataList = row.secondList.map(item => ({
        ...item,
        editor: false
      }))
      this.secondShow = true
      this.secondForm = {
        biomarker: row.biomarker_id,
        label_type: row.label_type_id,
        name: '',
        parent: row.id
      }
    },
    handleRowAdd(row, index) {
      this.secondForm.name = row.name
      addTag(this.secondForm).then(res => {
        if (res.code === 200) {
          this.secondShow = false
          Message.success('添加成功')
          this.$refs.formTable.isAdd = false
          this.$refs.formTable.isRowEditor = false
          this.secondDataList[index].editor = false
          this.fetchZhikuList()
        } else {
          Message.error(res.message)
        }
      })
    },
    handleRowSave(row) {
      // 二级标签修改问题
      this.secondForm.name = row.name
      editTag(row.id, this.secondForm).then(res => {
        if (res.code === 200) {
          this.secondShow = false
          Message.success('修改成功')
          this.fetchZhikuList()
        } else {
          Message.error(res.message)
        }
      })
    },
    handleRowDelete(row, index) {
      if (row.name === '') {
        this.secondDataList.splice(index, 1)
        return
      }
      if (row.id === undefined) {
        this.secondDataList.splice(index, 1)
        return
      }
      Modal.confirm({
        title: '是否删除此标签？',
        onOk: () => {
          deleteTag(row.id).then(res => {
            if (res.code === 200) {
              Message.success('删除成功')
              this.secondDataList.splice(index, 1)
              this.fetchZhikuList()
            } else {
              Message.error(res.message.indexOf('invalid') > -1 ? '标签已被使用，不能删除' : res.message)
            }
          })
        }
      })
    },
    handleFirstEditor(row) {
      this.firstShow = true
      this.formType = 'editor'
      this.addFirstForm = {
        id: row.id,
        biomarker: row.biomarker_id,
        label_type: row.label_type_id,
        name: row.name,
        parent: null
      }
    },
    handleFirstDel(id) {
      Modal.confirm({
        title: '是否删除此标签？',
        onOk: () => {
          deleteTag(id).then(res => {
            if (res.code === 200) {
              Message.success('删除成功')
              this.zhikuPage =
                this.firstDataList.length === 1 ? (this.zhikuPage === 1 ? 1 : this.zhikuPage - 1) : this.zhikuPage
              this.fetchZhikuList()
            } else {
              Message.error(res.message.indexOf('invalid') > -1 ? '标签已被使用，不能删除' : res.message)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
