<template>
  <div class="menu-page">
    <Z-Table :data="sourceData" :columns="columns" />
  </div>
</template>

<script>
import { Table, Button, Modal, Message } from 'view-design'
import { fetchMenus, fetchSystemPutMenu } from '@/api/system/'

export default {
  components: {
    'Z-Table': Table
  },
  data() {
    return {
      sourceData: [],
      columns: [
        {
          title: '序号',
          type: 'index',
          width: 120,
          align: 'center'
        },
        {
          title: '菜单名称',
          key: 'name'
        },
        {
          title: '菜单状态',
          key: 'state',
          render: (h, params) => {
            const { row } = params
            return h('div', row.state ? '可见' : '不可见')
          }
        },
        {
          title: '操作',
          slot: 'action',
          width: 240,
          render: (h, params) => {
            const { row } = params
            return (
              <div>
                {row.state ? (
                  <Button size="small" type="error" ghost onClick={() => this.editStatusHandle(row)}>
                    隐藏
                  </Button>
                ) : (
                  <Button size="small" type="primary" ghost onClick={() => this.editStatusHandle(row)}>
                    发布
                  </Button>
                )}
              </div>
            )
          }
        }
      ]
    }
  },
  mounted() {
    fetchMenus().then(res => {
      this.sourceData = res.data
    })
  },
  methods: {
    editStatusHandle(row) {
      const { id, state } = row
      Modal.confirm({
        title: '提示',
        content: `确定要${state ? '隐藏' : '发布'}该菜单吗？`,
        onOk: () => {
          // this.$Spin.show()
          fetchSystemPutMenu(id, {
            state: state ? 0 : 1
          })
            .then(res => {
              // this.$Spin.hide()
              Message.success('操作成功')
              this.sourceData = this.sourceData.map(item => {
                if (item.id === id) {
                  item.state = state ? 0 : 1
                }
                return item
              })
            })
            .catch(() => {
              // this.$Spin.hide()
            })
        }
      })
    }
  }
}
</script>

<sctyle lang="less" scoped>
.menu-page {
  background-color: #fff;
  border-radius: 0 0 12px 12px;
  min-height: calc(100vh - 320px);
  padding: 24px;

  .btn-wrap {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
  }
}
</sctyle>
