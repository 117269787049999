<template>
  <div class="user-filter-wrap">
    <FilterFeader
      :roles="roles"
      :page="page"
      @clear="isClear"
      @search="isSearch"
    />
    <div class="user-card-wrap">
      <div class="user-card-head">
        <div class="left">
          <Checkbox
            :indeterminate="unsure"
            :value="checkAll"
            @click.prevent.native="isCheckAll">
            <span class="all">全选</span>
          </Checkbox>
          <Divider type="vertical" />
          <div class="user-info">共<span>{{total}}</span>位用户，已选<span>{{checkAllGroup.length}}</span>位</div>
        </div>
        <div class="right" v-show="users.length && checkAllGroup.length">
          <Dropdown trigger="click" @on-click="isDropClick">
            <i class="iconfont iconyonghufenxi1"></i>
          <DropdownMenu slot="list">
            <DropdownItem v-for="role in roles" :key="role.label" :name="role.value">
              {{role.label}}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        </div>
      </div>
      <CheckboxGroup
        v-model="checkAllGroup"
        @on-change="checkAllGroupChange"
      >
        <div class="user-card-content">
          <skeleton
            style="width: 100%"
            v-if="loading"
            type='listcom'
            active
            :options="skeletonOption"
          />
          <div v-else-if="users.length === 0" class="empty">
            <img src="../../../../assets/imgs/empty.png">
          </div>
          <UserCard
            v-else
            :roles="roles"
            v-for="item in users"
            :key="item.id"
            :item="item"
            @switch="isSwitchClick"
            @drop="isDropChange"
          >
            <Icon :style="{ marginLeft: '8px', cursor: 'pointer' }" type="md-refresh" :size="20" @click="handleReset(item.id)" />
          </UserCard>
        </div>
      </CheckboxGroup>
      <div class="user-cards-footer">
        <Page
          :current="page"
          :page-size="8"
          :total="total"
          show-elevator
          @on-change="isPageChange"
        />
      </div>
    </div>
    <Modal
      class-name="vertical-center-modal"
      title="重置密码"
      v-model="showReset"
      @on-visible-change="isShowReset"
    >
      <div class="input-item">
        <div class="label">新密码：</div>
        <Input v-model="mm" type="password" placeholder="请输入" />
      </div>
      <div class="input-item">
        <div class="label">确认密码：</div>
        <Input v-model="confirmMm" type="password" placeholder="请输入" />
      </div>
      <div slot="footer">
        <Button @click="isResetCancel">取消</Button>
        <Button type="primary" @click="isResetOk">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { fetchSystemRoles, putPwd } from '@/api'
import { setSelected } from '@/utils/tools'
import FilterFeader from '../FilterHeader'
import UserCard from '../UserCard'
import { Checkbox, CheckboxGroup, Page, Divider, Dropdown, DropdownMenu, DropdownItem, Icon, Modal, Input, Message, Button } from 'view-design'
import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'UserFilter',
  data () {
    return {
      roles: [],
      unsure: false,
      checkAll: false,
      showReset: false,
      checkAllGroup: [],
      searchOpiton: {},
      mm: '',
      confirmMm: '',
      userId: -1
    }
  },

  props: {
    users: {
      type: Array,
      default: () => []
    },
    page: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: true
    },
    skeletonOption: {
      type: Object,
      default: () => {}
    }
  },

  created() {
    this.fetchOriginRoles()
  },

  components: {
    FilterFeader,
    Checkbox,
    CheckboxGroup,
    Page,
    UserCard,
    Divider,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Icon,
    Modal,
    Input,
    Button
  },

  methods: {
    async fetchOriginRoles() {
      const res = await fetchSystemRoles()
      this.roles = setSelected(res.data)
    },
    handleReset(id) {
      this.userId = id
      this.showReset = true
    },
    isCheckAll() {
      if (this.unsure) {
        this.checkAll = false
      } else {
        this.checkAll = !this.checkAll
      }
      this.unsure = false
      if (this.checkAll) {
        const temp = []
        this.users.forEach(item => temp.push(item.id))
        this.checkAllGroup = temp
      } else {
        this.checkAllGroup = []
      }
    },
    checkAllGroupChange(data) {
      if (data.length === this.users.length) {
        this.unsure = false
        this.checkAll = true
      } else if (data.length > 0) {
        this.unsure = true
        this.checkAll = false
      } else {
        this.unsure = false
        this.checkAll = false
      }
    },
    commonSetCheck() {
      this.checkAllGroup = []
      this.checkAll = false
      this.unsure = false
    },
    isPageChange(val) {
      this.$emit('page', { val, option: this.searchOpiton })
      this.commonSetCheck()
    },
    isClear(option) {
      this.searchOpiton = cloneDeep(option)
      this.$emit('clear', option)
      this.commonSetCheck()
    },
    isSearch(option) {
      this.searchOpiton = cloneDeep(option)
      this.$emit('search', option)
      this.commonSetCheck()
    },
    isDropClick(id) {
      this.$emit('role', { user_ids: this.checkAllGroup.join(','), role_id: id, option: this.searchOpiton })
      this.commonSetCheck()
    },
    isUserExport() {
      this.$emit('export', { user_ids: this.checkAllGroup.join(','), option: this.searchOpiton })
      this.commonSetCheck()
    },
    isSwitchClick(params) {
      this.$emit('switch', { params, option: this.searchOpiton })
      this.commonSetCheck()
    },
    isDropChange(params) {
      this.$emit('drop', { params, option: this.searchOpiton })
      this.commonSetCheck()
    },
    isShowReset(val) {
      if (!val) {
        this.userId = -1
        this.mm = ''
        this.confirmMm = ''
      }
    },
    isResetOk() {
      if (this.mm !== this.confirmMm) {
        Message.warning('两次密码不一致')
      } else if (this.mm === '' || this.confirmMm === '') {
        Message.warning('密码不能为空')
      } else {
        putPwd({ password: this.mm, confirm_password: this.confirmMm }, this.userId).then(res => {
          if (res.code === 200) {
            Message.success('重置密码成功')
            this.showReset = false
          } else {
            Message.error(res.msg)
          }
        }).catch(err => {
          this.showReset = false
          Message.error(err.msg)
        })
      }
    },
    isResetCancel() {
      this.mm = ''
      this.confirmMm = ''
      this.showReset = false
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');

.input-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .label {
    width: 100px;
  }
}
</style>

<style lang='less'>
.vertical-center-modal{
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal{
    top: 0;
  }
}
</style>
