<template>
  <div class="page-wrap">
    <div class="search-wrap">
      <div class="input-wrap">
        <Input v-model="search" size="large" clearable @on-clear="handleSearch" placeholder="请输入关键字搜索" />
        <Button size="large" type="primary" ghost style="margin-left: 8px;" @click="handleSearch">搜索</Button>
      </div>
      <Poptip trigger="hover" placement="bottom">
        <Button type="primary" size="large">信息上传</Button>
        <div slot="content">
          <div class="content-item" v-for="item in typeList" :key="item.value" @click="handleClick(item)">
            {{ item.name }}
          </div>
        </div>
      </Poptip>
    </div>
    <div class="content-wrap">
      <div class="total-wrap">
        <div>
          共 <span>{{ total }}</span> 条数据<span v-if="selection.length">，已选 {{ selection.length }} 条</span>
        </div>
        <div class="del" v-if="selection.length" @click="handleDels">
          <i class="iconfont iconshanchu" style="margin-right: 4px;"></i>批量删除
        </div>
      </div>
      <Z-Table :columns="columns" :data="dataList" @on-selection-change="handleSelection">
        <template #action="{ row }">
          <div>
            <Button type="primary" size="small" ghost style="margin-right: 8px;" @click="handleSubject(row)">{{
              !row.is_activate ? '发布' : '撤销发布'
            }}</Button>
            <Button type="primary" size="small" ghost style="margin-right: 8px;" @click="handleEditor(row)"
              >编辑</Button
            >
            <Button type="error" size="small" ghost @click="handleDelete(row)">删除</Button>
          </div>
        </template>
      </Z-Table>
      <div style="display: flex;justify-content: flex-end; margin-top: 16px;">
        <Page :total="total" @on-change="handlePageChange" />
      </div>
    </div>
    <GuideForm
      v-model="guideVisible"
      title="指南共识-信息上传"
      width="1000"
      :editorData="guideData"
      @refresh="handleRefresh"
      @close="handleClose"
    />
    <LiteratureForm
      v-model="literatureVisible"
      title="文献-信息上传"
      width="1000"
      :articleType="articleType"
      :editorData="literatureData"
      @refresh="handleRefresh"
      @close="handleClose"
    />
  </div>
</template>

<script>
import { Input, Button, Table, Poptip, Page, Message } from 'view-design'
import GuideForm from './guideForm.vue'
import LiteratureForm from './literatureForm.vue'
import {
  getLiteratureList,
  getLiteratureDetail,
  deleteLiterature,
  publishZhiKuLiterature,
  deleteZhiKuLiteratureList
} from '@/api/zhiku'
export default {
  components: {
    Input,
    Button,
    'Z-Table': Table,
    Poptip,
    GuideForm,
    LiteratureForm,
    Page
  },
  data() {
    return {
      page: 1,
      total: 0,
      search: '',
      selection: [],
      guideVisible: false,
      literatureVisible: false,
      literatureData: null,
      guideData: null,
      articleType: 2,
      columns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '标题',
          key: 'title',
          tooltip: true
        },
        {
          title: '发表年份',
          key: 'publish_year',
          width: 100
        },
        {
          title: '相关生物标志物',
          key: 'biomarkers',
          width: 200,
          tooltip: true
        },
        {
          title: '概述',
          key: 'abstract',
          tooltip: true
        },
        {
          title: '状态',
          key: 'is_activate',
          filters: [
            {
              label: '已发布',
              value: true
            },
            {
              label: '草稿',
              value: false
            }
          ],
          filterMultiple: false,
          filterRemote(value) {
            if (value.length) {
              this.fetchList({
                is_activate: value[0]
              })
            } else {
              this.fetchList()
            }
          },
          render: (h, params) => {
            return h('div', [h('span', params.row.is_activate ? '已发布' : '草稿')])
          }
        },
        {
          title: '操作',
          key: 'action',
          slot: 'action',
          width: 280
        }
      ],
      dataList: [],
      typeList: [
        { name: '指南共识', value: 1 },
        { name: '综述/Meta分析', value: 2 },
        { name: '研究', value: 3 },
        { name: '其他', value: 4 }
      ]
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    fetchList(filter) {
      getLiteratureList({
        page: this.page,
        search: this.search,
        ...filter,
        t: new Date().getTime()
      }).then(res => {
        if (res.code === 200) {
          this.dataList = res.data
          this.total = res.page.count
        }
      })
    },
    handleSearch() {
      this.page = 1
      this.fetchList()
    },
    handleRefresh() {
      this.literatureData = null
      this.guideData = null
      this.fetchList()
    },
    handleClose() {
      this.literatureData = null
      this.guideData = null
    },
    handleClick(item) {
      if (item.value === 1) {
        this.guideVisible = true
      } else {
        this.articleType = item.value
        this.literatureVisible = true
      }
    },
    handlePageChange(page) {
      this.page = page
      this.fetchList()
    },
    handleSubject(row) {
      console.log(row)
      publishZhiKuLiterature(row.id, {
        is_activate: !row.is_activate
      }).then(res => {
        console.log(res)
        if (res.code === 200) {
          Message.success('操作成功')
          this.fetchList()
        }
      })
    },
    handleEditor(row) {
      getLiteratureDetail(row.id).then(res => {
        if (row.article_data_type === 'article') {
          this.literatureData = {
            ...res.data,
            diseases: res.data.diseases.map(item => item.id),
            treatments: res.data.treatments.map(item => item.id),
            medicals: res.data.medicals.map(item => item.id),
            populations: res.data.populations.map(item => item.id),
            biomarker: res.data.biomarker.map(item => item.id),
            biomarker_tags: res.data.biomarker_tags.map(item => ({
              biomarker: item.biomarker && item.biomarker.id,
              instrument: item.instrument && item.instrument.id,
              reagent: item.reagent && item.reagent.id
            })),
            related_articles: res.data.related_articles.map(item => item.id),
            research_type: res.data.research_type.map(item => item.id)
          }
          this.literatureVisible = true
        } else {
          this.guideData = {
            ...res.data,
            diseases: res.data.diseases.map(item => item.id),
            treatments: res.data.treatments.map(item => item.id),
            medicals: res.data.medicals.map(item => item.id),
            populations: res.data.populations.map(item => item.id),
            biomarker: res.data.biomarker.map(item => item.id),
            biomarker_tags: res.data.biomarker_tags.map(item => ({
              biomarker: item.biomarker && item.biomarker.id,
              instrument: item.instrument && item.instrument.id,
              reagent: item.reagent && item.reagent.id
            })),
            related_articles: res.data.related_articles.map(item => item.id)
          }
          this.guideVisible = true
        }
      })
    },
    handleDelete(row) {
      deleteLiterature(row.id).then(res => {
        console.log(res)
        if (res.code === 200) {
          Message.success('删除成功')
          this.fetchList()
        }
      })
    },
    handleSelection(selection) {
      console.log(selection)
      this.selection = selection
    },
    handleDels() {
      console.log(this.selection)
      if (this.selection.length) {
        deleteZhiKuLiteratureList({ ids: this.selection.map(item => item.id) }).then(res => {
          console.log(res)
          if (res.code === 200) {
            Message.success('删除成功')
            this.selection = []
            this.fetchList()
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.page-wrap {
  .search-wrap {
    padding: 22px 40px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    border-radius: 0 0 12px 12px;

    .input-wrap {
      width: 60%;
      display: flex;
      align-items: center;
    }
  }

  .content-wrap {
    border-radius: 12px;
    background-color: #fff;
    padding: 40px;
    margin-top: 16px;
    min-height: calc(100vh - 440px);

    .total-wrap {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #656e77;
      margin-bottom: 16px;

      .del {
        color: #ff3c49;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }
  }

  .content-item {
    padding: 8px 0;
    cursor: pointer;
    &:hover {
      color: #007fff;
    }
  }
}
</style>
