<template>
  <div class="filter-header">
    <div class="row-one">
      <div class="filter-item search-item">
        <div class="label">搜索</div>
        <Input v-model="search" placeholder="请输入用户UserId" />
      </div>
      <div class="filter-item date-item">
        <div class="label">注册日期</div>
        <DatePicker
          type="daterange"
          v-model="date"
          placeholder="请选择起始时间"
          @on-change="handleDate"
        />
      </div>
      <div class="filter-item role-item">
        <div class="label">角色</div>
        <Select v-model="roleOption.role">
          <Option v-for="item in roleOption.roleList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      </div>
    </div>
    <div class="row-one row-two">
      <div class="left">
      </div>
      <div class="right">
        <Button type="default" style="margin-right: 12px" @click="isClear">清空</Button>
        <Button type="primary" @click="isSearch">搜索</Button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  fetchSystemArea,
  fetchSystemRoles,
  fetchSystemHospitalLevel,
  fetchSystemCompanyType
} from '@/api'
import { getSelectList, setSelected } from '@/utils/tools'
import { office, position } from '../../config'
import { Input, DatePicker, Select, Option, Button, Message } from 'view-design'
export default {
  name: 'FilterHeader',
  data () {
    return {
      search: '',
      date: [],
      roleOption: {
        role: '全部',
        roleList: [
          { label: '全部', value: '全部' }
        ]
      },
      areaOption: {
        area: [],
        areas: []
      },
      unitOption: {
        unit: '全部',
        units: [
          { label: '全部', value: '全部' }
        ]
      },
      hispotalOption: {
        level: '全部',
        levels: [
          { label: '全部', value: '全部' }
        ]
      },
      officeOption: {
        office: '全部',
        offices: getSelectList(office)
      },
      positionOption: {
        position: '全部',
        positions: getSelectList(position)
      }
    }
  },

  components: {
    Input,
    DatePicker,
    Select,
    Option,
    Button
  },

  computed: {
    filterOption({ search, date, roleOption, areaOption, unitOption, hispotalOption, officeOption, positionOption }) {
      return {
        search: search || undefined,
        date_joined__date__gte: date[0] || undefined,
        date_joined__date__lte: date[1] || undefined,
        role_id: roleOption.role === '全部' ? undefined : roleOption.role,
        province__in: areaOption.area.length ? areaOption.area.join(',') : undefined,
        company_type: unitOption.unit === '全部' ? undefined : unitOption.unit,
        company_level: hispotalOption.level === '全部' || unitOption.unit !== 1 ? undefined : hispotalOption.level,
        department: officeOption.office === '全部' || unitOption.unit !== 1 ? undefined : officeOption.office,
        position: positionOption.position === '全部' || unitOption.unit !== 1 ? undefined : positionOption.position
      }
    }
  },

  created() {
    this.fetchOriginArea()
    this.fetchOriginRoles()
    this.fetchOriginLevel()
    this.fetchOriginCompanyType()
  },

  methods: {
    async fetchOriginArea() {
      const res = await fetchSystemArea({ key: 'province' })
      this.areaOption.areas = setSelected(res.data)
    },
    async fetchOriginRoles() {
      const res = await fetchSystemRoles()
      this.roleOption.roleList = [...this.roleOption.roleList, ...setSelected(res.data)]
    },
    async fetchOriginLevel() {
      const res = await fetchSystemHospitalLevel({ key: 'company_level' })
      this.hispotalOption.levels = [...this.hispotalOption.levels, ...setSelected(res.data)]
    },
    async fetchOriginCompanyType() {
      const res = await fetchSystemCompanyType()
      this.unitOption.units = [...this.unitOption.units, ...setSelected(res.data)]
    },
    handleDate(val) {
      this.date = val
    },
    isAreaChange() {
      if (this.areaOption.area.length > 5) {
        Message.warning({
          content: '最多选择五个！',
          duration: 2
        })
        this.areaOption.area = this.areaOption.area.slice(0, 5)
      }
    },
    isClear() {
      this.search = ''
      this.date = []
      this.roleOption.role = '全部'
      this.areaOption.area = []
      this.unitOption.unit = '全部'
      this.hispotalOption.level = '全部'
      this.officeOption.office = '全部'
      this.positionOption.position = '全部'
      this.$emit('clear', this.filterOption)
    },
    isSearch() {
      this.$emit('search', this.filterOption)
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>