<template>
  <div class="system-wrap">
    <div class="system-header-card">
      <div class="system-header">
        <Tabs
          :tabs="tabs"
          :activeKey="tabKey"
          @active="isActive"
        />
        <div class="right" v-if="tabKey === '0'">
          <DateItem
            :lists="date"
            :activeKey="current"
            @active="handleDateItem"
          />
          <DatePicker
            type="daterange"
            v-model="dateRange"
            :clearable="false"
            placeholder="请选择起始时间"
            @on-change="handleDate"
          />
        </div>
      </div>
    </div>
    <div class="user-wrap" v-if="tabKey === '0'">
      <div class="user-left">
        <InfoCard
          v-for="item in cards"
          :key="item.label"
          :item="item"
        />
      </div>
      <div class="user-right" id="RegisterTrend">
        <div class="right-header">
          <div class="label">注册趋势</div>
          <div class="chart-icons" v-if="trendData.data.length">
            <Tooltip transfer content='导出' placement="bottom">
              <i class="iconfont iconxiazai1" @click="isRegisterExport"></i>
            </Tooltip>
            <template>
              <Tooltip transfer content='保存图片' placement="bottom">
                <i class="iconfont iconchakantubiao" @click="isSaveImg"></i>
              </Tooltip>
            </template>
          </div>
        </div>
      <div class='user-card-body'>
        <div>
          <skeleton
            v-if="trendData.loading"
            type='listcom'
            active
            :options="skeletonOption"
          />
          <div v-else-if="trendData.count === 0" class="empty">
            <img src="../../assets/imgs/empty.png">
          </div>
          <Curved
            v-else
            chartID="register"
            :height="210"
            :chartData="trendData.data"
          />
          </div>
        </div>
      </div>
    </div>
    <RoleManage v-if="tabKey === '1'" />
    <FeedBack v-if="tabKey === '2'" />
    <GuideUpload v-if="tabKey === '3'"  />
    <ZhiKuUpload v-if="tabKey === '4'" />
    <MeetingUpload v-if="tabKey === '5'" />
    <LabelPage v-if="tabKey === '6'" />
    <SubjectPage v-if="tabKey === '7'" />
    <MenuPage v-if="tabKey === '8'" />
    <template v-if="tabKey === '0'">
      <div class="system-content">
        <UserFilter
          :loading="cardLoading"
          :skeletonOption="skeletonOption"
          :users="users"
          :page="page"
          :total="total"
          @export="isExporClick"
          @role="isRoleChange"
          @page="isPage"
          @clear="isClearClick"
          @search="isSearchClick"
          @switch="isSwitchChange"
          @drop="isDropClickChange"
        />
      </div>
    </template>
  </div>
</template>

<script>
import {
  fetchSystemPreview,
  fetchSystemRegisterTrend,
  fetchSystemUsers,
  fetchSystemCompanyDistribute,
  fetchSystemPositionDistribute,
  fetchSystemHospitalLevelDistribute,
  fetchSystemDepartment,
  fetchSystemProvinceDistribute,
  fetchSystemPutUserRole
} from '@/api'

import { tabs, cards, date, useAreaTableColumns } from './config'
import Tabs from './components/Tabs'
import DateItem from './components/DateItem'
import InfoCard from './components/InfoCard'
import UserFilter from './components/UserFilter'
// import Table from 'components/Table'
// import ChardCard from 'components/ChardCard'
import FeedBack from './feedback'
import RoleManage from './role'
import GuideUpload from './guide'
import MeetingUpload from './meeting'
import ZhiKuUpload from './zhiku'
import LabelPage from './label'
import SubjectPage from './subject'
import MenuPage from './menu'
import {
  Curved
  // Histogram,
  // Pie,
  // Map
} from 'components/Charts'
import {
  DatePicker,
  // Row,
  // Col,
  // RadioGroup,
  // Radio,
  Tooltip,
  Message,
  Spin
} from 'view-design'
import domtoimage from 'dom-to-image'
import {
  loadingSpin,
  setFilesExport,
  getAssignFormatTime,
  setUserCommonExport
} from '@/utils/tools'

export default {
  name: 'System',
  data () {
    return {
      skeletonOption: {
        row: 8,
        lineHight: 10
      },
      cardLoading: true,
      tabKey: '0',
      tabs,
      current: '0',
      cards,
      date,
      dateItem: '',
      dateRange: [],
      trendData: {
        data: [],
        count: 0,
        loading: true
      },
      registerTrend: {
        data: [],
        count: 0,
        loading: true
      },
      unitType: {
        data: [],
        count: 0,
        loading: true
      },
      positionDistribute: {
        data: [],
        count: 0,
        loading: true
      },
      hospiatlLevels: {
        data: [],
        count: 0,
        loading: true
      },
      officesRegister: {
        data: [],
        count: 0,
        loading: true
      },
      userArea: {
        data: [],
        count: 0,
        loading: true
      },
      showChart: false,
      useAreaTable: [],
      useAreaTableColumns,
      userCurrent: '全部',
      users: [],
      total: 0,
      page: 1,
      doctorCurrent: '医院'
    }
  },
  components: {
    Tabs,
    DateItem,
    DatePicker,
    InfoCard,
    Curved,
    // ChardCard,
    // Row,
    // Col,
    // Histogram,
    // Pie,
    // Map,
    // Table,
    // RadioGroup,
    // Radio,
    UserFilter,
    Tooltip,
    FeedBack,
    RoleManage,
    GuideUpload,
    MeetingUpload,
    ZhiKuUpload,
    LabelPage,
    SubjectPage,
    MenuPage
  },

  computed: {
    setDateType({ current }) {
      if (!current) {
        return 'default'
      } else {
        return current === '0' ? 'week' : 'month'
      }
    },
    setChartRelateParams({ dateRange }) {
      return {
        date_joined__gte: (dateRange.length && dateRange[0]) ? dateRange[0] : getAssignFormatTime(1, undefined, this.setDateType),
        date_joined__lte: (dateRange.length && dateRange[1]) ? dateRange[1] : getAssignFormatTime(1, undefined)
      }
    }
  },

  watch: {
    tabKey: {
      handler(val) {
        if (val === '0') {
          this.fetchOriginPreview()
          this.commontFetch()
          this.fetchOriginUsers()
        }
      },
      immediate: true
    }
  },

  methods: {
    async fetchOriginPreview() {
      const res = await fetchSystemPreview()
      const { total_added, yesterday_added } = res.data
      this.cards[0].count = yesterday_added
      this.cards[1].count = total_added
    },
    commonSetData(res, key) {
      this[key].loading = true
      const { all_count, data } = res
      this[key].data = data
      this[key].count = all_count
      this[key].loading = false
    },
    async fetchOriginTrend() {
      const option = {
        ...this.setChartRelateParams
      }
      const res = await fetchSystemRegisterTrend(option)
      this.commonSetData(res.data, 'trendData')
    },
    async fetchOriginCompany() {
      const option = {
        ...this.setChartRelateParams
      }
      const res = await fetchSystemCompanyDistribute(option)
      this.commonSetData(res.data, 'unitType')
    },
    async fetchOriginPositon() {
      const option = {
        ...this.setChartRelateParams,
        company_type: this.doctorCurrent === '医院' ? '1' : '2'
      }
      const res = await fetchSystemPositionDistribute(option)
      this.commonSetData(res.data, 'positionDistribute')
    },
    async fetchOriginHospital() {
      const res = await fetchSystemHospitalLevelDistribute({ ...this.setChartRelateParams })
      this.commonSetData(res.data, 'hospiatlLevels')
    },
    async fetchOriginDepartment() {
      const res = await fetchSystemDepartment({ ...this.setChartRelateParams, company_type: '1' })
      this.commonSetData(res.data, 'officesRegister')
    },
    async fetchOriginPrvince() {
      const option = {
        ...this.setChartRelateParams,
        company_type: this.userCurrent !== '全部'
          ? this.userCurrent === '医院'
            ? '1'
            : '2'
          : undefined
      }
      const res = await fetchSystemProvinceDistribute(option)
      this.useAreaTable = res.data.data.map(v => ({
        province: v.name,
        pre: v.freq,
        ...v
      }))
      this.commonSetData(res.data, 'userArea')
    },
    async fetchOriginUsers(options = {}) {
      this.cardLoading = true
      const option = {
        page: this.page,
        page_size: 8,
        ...options
      }
      const res = await fetchSystemUsers(option)
      const { data, page } = res.data
      this.users = data.map(v => ({
        ...v,
        banned: !v.is_banned
      }))
      this.total = page.count
      this.cardLoading = false
    },
    isActive(key) {
      this.tabKey = key
    },
    isRegisterExport() {
      setUserCommonExport(this.setChartRelateParams, 'users/dashboard/register/export')
    },
    isCompanyTypeExport() {
      setUserCommonExport(this.setChartRelateParams, 'users/dashboard/company_type/export')
    },
    isPositionDistributeExport() {
      const option = {
        ...this.setChartRelateParams,
        company_type: this.doctorCurrent === '医院' ? '1' : '2'
      }
      setUserCommonExport(option, 'users/dashboard/position/export')
    },
    isHospiatlLevelExport() {
      setUserCommonExport(this.setChartRelateParams, 'users/dashboard/hospital_level/export')
    },
    isOfficesRegisterExport() {
      setUserCommonExport(this.setChartRelateParams, 'users/dashboard/department/export')
    },
    isUserAreaExport() {
      const option = {
        ...this.setChartRelateParams,
        company_type: this.userCurrent !== '全部'
          ? this.userCurrent === '医院'
            ? '1'
            : '2'
          : undefined
      }
      setUserCommonExport(option, 'users/dashboard/province/export')
    },
    async isSaveImg() {
      loadingSpin()
      const el = document.getElementById('RegisterTrend')
      try {
        const blob = await domtoimage.toBlob(el)
        setFilesExport(blob, '注册趋势')
        Spin.hide()
        Message.success({
          content: '图片下载成功',
          duration: 2
        })
      } catch (error) {
        Message.error({
          content: '图片下载失败',
          duration: 2
        })
      }
    },
    commontFetch() {
      this.trendData.loading = true
      this.registerTrend.loading = true
      this.unitType.loading = true
      this.positionDistribute.loading = true
      this.hospiatlLevels.loading = true
      this.officesRegister.loading = true
      this.userArea.loading = true
      this.fetchOriginTrend()
      this.fetchOriginCompany()
      this.fetchOriginPositon()
      this.fetchOriginHospital()
      this.fetchOriginDepartment()
      this.fetchOriginPrvince()
    },
    handleDateItem(key, value) {
      this.current = key
      this.dateItem = value
      this.dateRange = []
      this.commontFetch()
    },
    handleDate(date) {
      this.dateRange = date
      this.current = ''
      this.commontFetch()
    },
    handleToggle() {
      this.showChart = !this.showChart
    },
    handleUserRadio(val) {
      this.userArea.loading = true
      this.userCurrent = val
      this.fetchOriginPrvince()
    },
    handleDoctor(val) {
      this.doctorCurrent = val
      this.positionDistribute.loading = true
      this.fetchOriginPositon()
    },
    isPage({ val, option }) {
      this.page = val
      this.fetchOriginUsers(option)
    },
    isClearClick(option) {
      this.page = 1
      this.fetchOriginUsers(option)
    },
    isSearchClick(option) {
      this.page = 1
      this.fetchOriginUsers(option)
    },
    async isRoleChange({ user_ids, role_id, option }) {
      const res = await fetchSystemPutUserRole({ user_ids, role_id })
      if (res.message === 'OK') {
        Message.success({
          content: '修改成功！',
          duration: 1
        })
        this.fetchOriginUsers(option)
      }
    },
    isExporClick({ user_ids, option }) {
      const options = {
        page: this.page,
        id__in: user_ids,
        ...option
      }
      setUserCommonExport(options, 'users/accounts_export', {}, true)
    },
    async isSwitchChange({ params, option }) {
      const res = await fetchSystemPutUserRole({ user_ids: String(params.id), is_banned: !params.bool })
      if (res.message === 'OK') {
        Message.success({
          content: '修改成功！',
          duration: 1
        })
        this.fetchOriginUsers(option)
      }
    },
    async isDropClickChange({ params, option }) {
      const res = await fetchSystemPutUserRole({ user_ids: String(params.user_id), role_id: params.role_id })
      if (res.message === 'OK') {
        Message.success({
          content: '修改成功！',
          duration: 1
        })
        this.fetchOriginUsers(option)
      }
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>
