<template>
  <div class="form-container">
    <div class="form-body">
      <div class="item-wrap">
        <div class="label-wrap">文献类型</div>
        <Select v-model="type" style="width: 160px;" label-in-value @on-change="handelTypeChange" :disabled="submitType === 'editor' || isView">
          <Option v-for="(item, index) in typeList" :key="index" :value="item.id">{{ item.name }}</Option>
        </Select>
      </div>
      <!-- 指南上传 -->
      <Form
        v-if="articleType === '指南共识'"
        ref="formValidate"
        :model="formOption"
        :rules="ruleValidate"
        label-position="right"
        :label-width="80"
      >
        <FormItem label="指南标题" prop="title">
          <Input v-model="formOption.title" :disabled="submitType === 'editor' || isView" placeholder="请输入指南标题"></Input>
        </FormItem>

        <FormItem v-if="formOption.authors.length" label="作者" prop="author" style="margin-bottom: 0;">
          <div v-for="(item, index) in formOption.authors" :key="index" style="display: flex; margin-bottom: 24px;">
            <Input v-model="formOption.authors[index]" :disabled="submitType === 'editor' || isView" placeholder="请输入作者"></Input>
            <div class="icon-area" v-if="submitType === 'add' && !isView">
              <template v-if="formOption.authors.length > 1">
                <em class="iconfont iconjian" @click="handelDeleteAuthors(index)" />
              </template>
              <template v-if="index === formOption.authors.length - 1">
                <em class="iconfont iconjia" @click="handelAddAuthors"  />
              </template>
            </div>
          </div>
        </FormItem>

        <Row>
          <Col>
            <FormItem label="发表年份" prop="publish_year">
              <DatePicker type="year" placeholder="请选择年份" :disabled="submitType === 'editor' || isView" v-model="formOption.publish_year"></DatePicker>
            </FormItem>
          </Col>
          <Col style="margin-left: 16px;">
            <FormItem label="语言类型" prop="language">
              <Select v-model="formOption.language" :disabled="isView" style="width: 160px;">
                <Option value="chi">中文</Option>
                <Option value="eng">外文</Option>
              </Select>
            </FormItem>
          </Col>
          <Col style="flex: 1">
            <FormItem label="下载链接" prop="url">
              <Input v-model="formOption.source_url" :disabled="isView" placeholder="请输入下载链接"></Input>
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col style="flex: 1;">
            <FormItem label="发布机构" prop="institute">
              <Input v-model="formOption.institute" :disabled="isView" placeholder="请输入发布机构"></Input>
            </FormItem>
          </Col>
          <Col style="flex: 1;">
            <FormItem label="期刊" prop="journal">
              <Input v-model="formOption.journal" :disabled="isView" placeholder="请输入期刊名"></Input>
            </FormItem>
          </Col>
          <Col style="flex: 1;">
            <FormItem label="影响因子" prop="impact_factor">
              <Input v-model="formOption.impact_factor" @on-change="handleImpactFactor" :disabled="isView" placeholder="请输入影响因子"></Input>
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col style="flex: 1;">
            <FormItem label="DOI" prop="doi">
              <Input v-model="formOption.doi" :disabled="submitType === 'editor' || isView" placeholder="请输入DOI"></Input>
            </FormItem>
          </Col>
          <Col style="flex: 1;">
            <FormItem label="研究人群" prop="population">
              <Select v-model="formOption.population" :disabled="isView" multiple transfer :max-tag-count="2">
                <Option v-for="item in populationOption" :key="item.id" :value="item.id">{{ item.name }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col style="flex: 1;">
            <FormItem label="临床应用" prop="clinical_application">
              <Select v-model="formOption.clinical_application" :disabled="isView" multiple transfer :max-tag-count="2">
                <Option v-for="item in clinicalOption" :key="item.id" :value="item.id">{{ item.name }}</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>

        <FormItem
          label="内容解读"
          v-for="(item, index) in formOption.interpreter"
          :key="index"
          prop="interpreter"
        >
          <Row type="flex">
            <Col>
              <Select v-model="item.name" :disabled="isView" >
                <Option v-for="p in sourceList" :key="p.value" :value="p.value">{{ p.name }}</Option>
              </Select>
            </Col>
            <Col style="margin-left: 16px; display: flex; flex: 1;">
              <Input v-model="item.url" :disabled="isView" placeholder="请输入解读链接"></Input>
              <div class="icon-area" v-if="!isView">
                <template v-if="formOption.interpreter.length > 1">
                  <em class="iconfont iconjian" @click="handelDeleteParse(index)" />
                </template>
                <template v-if="index === formOption.interpreter.length - 1">
                  <em class="iconfont iconjia" @click="handelAddParse"  />
                </template>
              </div>
            </Col>
          </Row>
        </FormItem>

        <FormItem class="label-wrap">
          <div class="table-card">
            <div class="title">标签设置</div>
            <div class="body-wrap">
              <FieldTable :columns="columns" :data="formOption.labels">
                <template slot-scope="{ row, index }" slot="field">
                  <div v-if="row.editor">
                    <Cascader :value="row.field" :data="fieldsOption" transfer @on-change="(values) => { handelFieldChange(values, index) }"></Cascader>
                  </div>
                  <div v-else>{{ formatCascaderLableData(row.field) }}</div>
                </template>
                <template slot-scope="{ row, index }" slot="biomarker">
                  <div v-if="row.editor">
                    <Select v-model="row.bio_markers" multiple transfer :max-tag-count="2">
                      <Option v-for="item in biomarkerOption" :key="item.id" :value="item.id">{{ item.name }}</Option>
                    </Select>
                  </div>
                  <div v-else>{{ formOption.labelsText[index].bio_markers }}</div>
                </template>
                <template slot-scope="{ row, index }" slot="disease">
                  <div v-if="row.editor">
                    <Select v-model="row.disease" multiple transfer :max-tag-count="2">
                      <Option v-for="item in diseaseOption" :key="item.id" :value="item.id">{{ item.name }}</Option>
                    </Select>
                  </div>
                  <div v-else>{{ formOption.labelsText[index].disease }}</div>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                  <div v-if="row.editor">
                    <Button class="primary-btn" size="small" type="default" @click="handelRowSave(index, row)">{{ isAdd ? '添加' : '保存' }}</Button>
                    <Button :class="isAdd ? 'delete-btn' : 'default-btn'" style="margin-left: 16px;" size="small" type="default" @click="handelRowCancel(index)">{{ isAdd ? '删除' : '取消' }}</Button>
                  </div>
                  <div v-else>
                    <Button :class="{'default-btn': !isRowEditor}" :disabled="isRowEditor" size="small" type="default" @click="handelRowEditor(index)">编辑</Button>
                    <Button :class="{'delete-btn': !isRowEditor}" :disabled="isRowEditor" style="margin-left: 16px;" size="small" type="default" @click="handelRowDelete(index)">删除</Button>
                  </div>
                </template>
              </FieldTable>
              <Button style="margin-top: 16px;" :disabled="isRowEditor || isView" type="dashed" long @click="handelAddRow">添加标签</Button>
            </div>
          </div>
        </FormItem>
      </Form>
      <!-- 文献上传 -->
      <Form
        v-else
        ref="literatureRuleValidate"
        :model="literatureFormOption"
        :rules="literatureRuleValidate"
        label-position="right"
        :label-width="80"
      >
        <FormItem label="文献标题" prop="title">
          <Input v-model="literatureFormOption.title" :disabled="submitType === 'editor' || isView" placeholder="请输入文献标题"></Input>
        </FormItem>

        <FormItem v-if="literatureFormOption.authors.length" label="作者" prop="authors" style="margin-bottom: 0;">
          <div v-for="(item, index) in literatureFormOption.authors" :key="index" style="display: flex; margin-bottom: 24px;">
            <Input v-model="literatureFormOption.authors[index]" :disabled="submitType === 'editor' || isView" placeholder="请输入作者"></Input>
            <div class="icon-area" v-if="submitType === 'add' && !isView">
              <template v-if="literatureFormOption.authors.length > 1">
                <em class="iconfont iconjian" @click="handelDeleteAuthors(index)" />
              </template>
              <template v-if="index === literatureFormOption.authors.length - 1">
                <em class="iconfont iconjia" @click="handelAddAuthors"  />
              </template>
            </div>
          </div>
        </FormItem>

        <Row>
          <Col>
            <FormItem label="发表年份" prop="publish_year">
              <DatePicker type="year" placeholder="请选择年份" :disabled="submitType === 'editor' || isView" v-model="literatureFormOption.publish_year"></DatePicker>
            </FormItem>
          </Col>
          <Col style="margin-left: 16px;">
            <FormItem label="语言类型" prop="language">
              <Select v-model="literatureFormOption.language" :disabled="isView" style="width: 160px;">
                <Option value="chi">中文</Option>
                <Option value="eng">外文</Option>
              </Select>
            </FormItem>
          </Col>
          <Col style="flex: 1">
            <FormItem label="影响因子" prop="impact_factor">
              <Input v-model="literatureFormOption.impact_factor" @on-change="handleImpactFactor" :disabled="isView" placeholder="请输入影响因子"></Input>
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col style="flex: 1;">
            <FormItem label="期刊" prop="journal">
              <Input v-model="literatureFormOption.journal" :disabled="isView" placeholder="请输入期刊"></Input>
            </FormItem>
          </Col>
          <Col style="flex: 1;">
            <FormItem label="DOI" prop="doi">
              <Input v-model="literatureFormOption.doi" :disabled="submitType === 'editor' || isView" placeholder="请输入DOI"></Input>
            </FormItem>
          </Col>
          <Col style="flex: 1;">
            <FormItem label="PMID" prop="pmid">
              <Input v-model="literatureFormOption.pmid" :disabled="isView" placeholder="请输入PMID"></Input>
            </FormItem>
          </Col>
        </Row>

        <FormItem v-if="literatureFormOption.keywords.length" label="关键词" prop="keywords" style="margin-bottom: 0;">
          <div v-for="(item, index) in literatureFormOption.keywords" :key="index" style="display: flex; margin-bottom: 24px;">
            <Input v-model="literatureFormOption.keywords[index].key_word" :disabled="isView" placeholder="请输入关键词"></Input>
            <div class="icon-area" v-if="submitType === 'add' && !isView">
              <template v-if="literatureFormOption.keywords.length > 1">
                <em class="iconfont iconjian" @click="handelDeleteKeywords(index)" />
              </template>
              <template v-if="index === literatureFormOption.keywords.length - 1">
                <em class="iconfont iconjia" @click="handelAddKeywords"  />
              </template>
            </div>
          </div>
        </FormItem>

        <Row>
          <Col style="flex: 1;">
            <FormItem label="研究人群" prop="population">
              <Select v-model="literatureFormOption.population" :disabled="isView" multiple transfer :max-tag-count="2">
                <Option v-for="item in populationOption" :key="item.id" :value="item.id">{{ item.name }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col style="flex: 1;">
            <FormItem label="临床应用" prop="clinical_application">
              <Select v-model="literatureFormOption.clinical_application" :disabled="isView" multiple transfer :max-tag-count="2">
                <Option v-for="item in clinicalOption" :key="item.id" :value="item.id">{{ item.name }}</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>

        <FormItem label="文献来源">
          <div style="display: flex;">
            <FormItem style="margin-right: 16px;" prop="source">
              <Select v-model="literatureFormOption.source" :disabled="isView" style="width: 120px;" transfer :max-tag-count="2">
                <Option value="pubmed">Pubmed</Option>
                <Option value="知网">中国知网</Option>
                <Option value="万方医学">万方医学</Option>
                <Option value="中华医学期刊网">中华医学期刊网</Option>
              </Select>
            </FormItem>
            <FormItem style="flex: 1;" prop="source_url">
              <Input v-model="literatureFormOption.source_url" :disabled="isView" placeholder="请输入来源链接"></Input>
            </FormItem>
          </div>
        </FormItem>

        <FormItem class="label-wrap">
          <div class="table-card">
            <div class="title">标签设置</div>
            <div class="body-wrap">
              <FieldTable :columns="columns" :data="literatureFormOption.labels">
                <template slot-scope="{ row, index }" slot="field">
                  <div v-if="row.editor">
                    <Cascader :value="row.field" :data="fieldsOption" transfer @on-change="(values) => { handelFieldChange(values, index) }"></Cascader>
                  </div>
                  <div v-else>{{ formatCascaderLableData(row.field) }}</div>
                </template>
                <template slot-scope="{ row, index }" slot="biomarker">
                  <div v-if="row.editor">
                    <Select v-model="row.bio_markers" multiple transfer :max-tag-count="2">
                      <Option v-for="item in biomarkerOption" :key="item.id" :value="item.id">{{ item.name }}</Option>
                    </Select>
                  </div>
                  <div v-else>{{ literatureFormOption.labelsText[index].bio_markers }}</div>
                </template>
                <template slot-scope="{ row, index }" slot="disease">
                  <div v-if="row.editor">
                    <Select v-model="row.disease" multiple transfer :max-tag-count="2">
                      <Option v-for="item in diseaseOption" :key="item.id" :value="item.id">{{ item.name }}</Option>
                    </Select>
                  </div>
                  <div v-else>{{ literatureFormOption.labelsText[index].disease }}</div>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                  <div v-if="row.editor">
                    <Button class="primary-btn" size="small" type="default" @click="handelRowSave(index, row)">{{ isAdd ? '添加' : '保存' }}</Button>
                    <Button :class="isAdd ? 'delete-btn' : 'default-btn'" style="margin-left: 16px;" size="small" type="default" @click="handelRowCancel(index)">{{ isAdd ? '删除' : '取消' }}</Button>
                  </div>
                  <div v-else>
                    <Button :class="{'default-btn': !isRowEditor}" :disabled="isRowEditor" size="small" type="default" @click="handelRowEditor(index)">编辑</Button>
                    <Button :class="{'delete-btn': !isRowEditor}" :disabled="isRowEditor" style="margin-left: 16px;" size="small" type="default" @click="handelRowDelete(index)">删除</Button>
                  </div>
                </template>
              </FieldTable>
              <Button style="margin-top: 16px;" :disabled="isRowEditor || isView" type="dashed" long @click="handelAddRow">添加标签</Button>
            </div>
          </div>
        </FormItem>
      </Form>
    </div>
    <div v-if="!isView" class="form-footer">
      <Button type="primary" @click="handleSubmit">确定</Button>
      <Button style="margin-left: 8px" @click="handelCancel">取消</Button>
    </div>
  </div>
</template>

<script>
import { isEmpty } from '@antv/util'
import {
  Form,
  FormItem,
  Input,
  DatePicker,
  Row,
  Col,
  Select,
  Option,
  Message,
  Button,
  Table,
  Cascader
} from 'view-design'
// import { deepCopy } from '@/utils/tools'
import dayjs from 'dayjs'
import {
  fetchFields,
  fetchBiomarkers,
  fetchDisease,
  fetchClinicals,
  fetchPopulations
} from '@/api/upload/'

const errorMap = {
  0: '请选择领域',
  1: '请选择生物标志物',
  2: '请选择相关疾病',
  3: '请选择临床应用',
  4: '请选者研究人群'
}

export default {
  name: 'single',
  props: {
    typeList: {
      type: Array,
      default: () => []
    },
    editorData: {
      type: Object,
      default: () => null
    },
    isView: {
      type: Boolean,
      default: false
    },
    viewData: {
      type: Object,
      default: () => null
    }
  },
  components: {
    Form,
    FormItem,
    Input,
    DatePicker,
    Row,
    Col,
    Select,
    Option,
    Button,
    FieldTable: Table,
    Cascader
  },
  data() {
    return {
      isRowEditor: false,
      isAdd: false,
      type: '',
      articleType: '指南共识',
      submitType: 'add',
      // 指南上传表单
      formOption: {
        title: '',
        authors: [''],
        publish_year: '',
        language: '',
        source_url: '',
        institute: '',
        journal: '',
        impact_factor: null,
        doi: '',
        population: [],
        clinical_application: [],
        interpreter: [{ name: '医脉通', url: '' }],
        labels: [],
        labelsText: []
      },
      ruleValidate: {
        literatureType: [{ required: true, message: '请选择文献类型', trigger: 'change' }],
        title: [{ required: true, message: '请输入指南标题', trigger: 'blur' }],
        publish_year: [{ required: true, type: 'date', message: '请选者年份', trigger: 'change' }],
        language: [{ required: true, message: '请选者语言类型', trigger: 'change' }],
        institute: [{ required: true, message: '请输入发布机构', trigger: 'blur' }]
      },
      sourceList: [
        { name: '医脉通', value: '医脉通' },
        { name: '大道至检e课堂', value: '大道至检e课堂' },
        { name: '原文解读', value: '原文解读' }
      ],
      // columns: [
      //   { title: '疾病领域', slot: 'field' },
      //   { title: '生物标志物', slot: 'biomarker' },
      //   { title: '相关疾病', slot: 'disease' },
      //   // { title: '临床应用', slot: 'clinical' },
      //   // { title: '研究人群', slot: 'population' },
      //   { title: '操作', slot: 'action' }
      // ],
      // 文献上传表单
      literatureFormOption: {
        title: '',
        authors: [''],
        publish_year: '',
        language: '',
        impact_factor: null,
        journal: '',
        doi: '',
        pmid: '',
        keywords: [{ key_word: '' }],
        population: [],
        clinical_application: [],
        source: '',
        source_url: '',
        labels: [],
        labelsText: []
      },
      literatureRuleValidate: {
        title: [{ required: true, message: '请输入文献标题', trigger: 'blur' }],
        publish_year: [{ required: true, type: 'date', message: '请选者年份', trigger: 'change' }],
        language: [{ required: true, message: '请选者语言类型', trigger: 'change' }],
        journal: [{ required: true, message: '请输入期刊', trigger: 'blur' }]
      },
      fieldsOption: [],
      biomarkerOption: [],
      diseaseOption: [],
      clinicalOption: [],
      populationOption: []
    }
  },
  computed: {
    columns() {
      if (this.isView) {
        return [
          { title: '疾病领域', slot: 'field' },
          { title: '生物标志物', slot: 'biomarker' },
          { title: '相关疾病', slot: 'disease' }
          // { title: '临床应用', slot: 'clinical' },
          // { title: '研究人群', slot: 'population' },
        ]
      } else {
        return [
          { title: '疾病领域', slot: 'field' },
          { title: '生物标志物', slot: 'biomarker' },
          { title: '相关疾病', slot: 'disease' },
          // { title: '临床应用', slot: 'clinical' },
          // { title: '研究人群', slot: 'population' },
          { title: '操作', slot: 'action' }
        ]
      }
    }
  },
  watch: {
    typeList: {
      handler(val) {
        if (val.length) {
          this.type = val.find(item => item.name === this.articleType).id
        }
      },
      deep: true,
      immediate: true
    },
    editorData: {
      handler(val) {
        if (val) {
          this.type = val.article_type[0]
          this.articleType = this.typeList.find(item => item.id === val.article_type[0]).name
          this.submitType = 'editor'
          if (this.articleType === '指南共识') {
            this.formOption = {
              ...val,
              authors: [''],
              interpreter: [{ name: '医脉通', url: '' }]
            }
          } else {
            this.literatureFormOption = {
              ...val,
              authors: [''],
              keywords: [{ key_word: '' }]
            }
          }
        } else {
          this.submitType = 'add'
        }
      },
      deep: true,
      immediate: true
    },
    viewData: {
      handler(val) {
        if (val) {
          this.type = val.article_type[0]
          this.articleType = this.typeList.find(item => item.id === val.article_type[0]).name
          this.articleType === '指南共识' ? this.formOption = val : this.literatureFormOption = val
          this.submitType = 'add'
        }
      },
      deep: true,
      immediate: true
    }
    // formOption: {
    //   handler(val) {
    //     console.log(val)
    //   },
    //   deep: true
    // }
  },
  created() {
    fetchFields().then(res => {
      if (res.code === 200) {
        const arr = res.data.map(item => ({
          id: item.id,
          name: item.name,
          children: item.items
        }))
        this.fieldsOption = this.formatCascaderData(arr)
      }
    })
    fetchClinicals().then(res => {
      if (res.code === 200) {
        this.clinicalOption = res.data
      }
    })
    fetchPopulations().then(res => {
      if (res.code === 200) {
        this.populationOption = res.data
      }
    })
  },
  methods: {
    // 格式化级联菜单数据
    formatCascaderData(arr) {
      const newArr = []
      arr.forEach((item, i) => {
        if (item.children.length) {
          newArr[i] = { value: item.id, label: item.name, children: this.formatCascaderData(item.children) }
        } else {
          newArr[i] = { value: item.id, label: item.name, children: item.children || [] }
        }
      })
      return newArr
    },
    formatData(arr) {
      const newArr = []
      arr.forEach((item, i) => {
        if (item.children.length) {
          newArr[i] = [{ label: item.label, value: item.value }, ...this.formatData(item.children)]
        } else {
          newArr[i] = { label: item.label, value: item.value }
        }
      })
      return newArr
    },
    flatten(arr, depth = 1) {
      return arr.reduce(
        (a, v) =>
          a.concat(depth > 1 && Array.isArray(v) ? this.flatten(v, depth - 1) : v),
        []
      )
    },
    // 格式化级联展示数据
    formatCascaderLableData(values) {
      const arr = this.formatData(this.fieldsOption)
      const labelArr = []
      this.flatten(arr).forEach(item => {
        values.indexOf(item.value) !== -1 && labelArr.push(item.label)
      })
      return labelArr.join('/')
    },
    // 影响因子处理
    handleImpactFactor(e) {
      const val = e.target.value
      if (val === '') {
        this.articleType === '指南共识' ? this.formOption.impact_factor = null : this.literatureFormOption.impact_factor = null
      }
    },
    // 格式化标签展示数据
    formatLabelData(values, option) {
      const arr = []
      option.forEach(item => {
        values.indexOf(item.id) !== -1 && arr.push(item.name)
      })
      return arr.join(',')
    },
    // 上传类型选择
    handelTypeChange(data) {
      console.log(data)
      this.articleType = data.label
      this.$nextTick(() => {
        data.label === '指南共识' ? this.$refs.formValidate.resetFields() : this.$refs.literatureRuleValidate.resetFields()
      })
    },
    // 添加作者
    handelAddAuthors() {
      if (this.articleType === '指南共识') {
        this.formOption.authors.push('')
      } else {
        this.literatureFormOption.authors.push('')
      }
    },
    // 删除作者
    handelDeleteAuthors(index) {
      if (this.articleType === '指南共识') {
        this.formOption.authors.splice(index, 1)
      } else {
        this.literatureFormOption.authors.splice(index, 1)
      }
    },
    // 添加关键词
    handelAddKeywords() {
      this.literatureFormOption.keywords.push({ key_word: '' })
    },
    // 删除关键词
    handelDeleteKeywords(index) {
      this.literatureFormOption.keywords.splice(index, 1)
    },
    // 添加内容解读
    handelAddParse() {
      if (this.formOption.interpreter.length < this.sourceList.length) {
        this.formOption.interpreter.push({
          name: '医脉通',
          url: ''
        })
      } else {
        Message.warning('最多可以增加三条内容解读')
      }
    },
    // 删除内容解读
    handelDeleteParse(index) {
      this.formOption.interpreter.splice(index, 1)
    },
    // 编标签行数据
    handelRowEditor(index) {
      let value = []
      if (this.articleType === '指南共识') {
        value = this.formOption.labels[index].field
      } else {
        value = this.literatureFormOption.labels[index].field
      }
      if (value.length) {
        Promise.all([fetchBiomarkers({
          fields: value.join(',')
        }), fetchDisease({
          field_id__in: value.join(',')
        })]).then(res => {
          this.biomarkerOption = res[0].data
          this.diseaseOption = res[1].data
          this.articleType === '指南共识'
            ? this.formOption.labels[index].editor = true
            : this.literatureFormOption.labels[index].editor = true
          this.isRowEditor = true
        })
      }
    },
    // 取消编辑标签行数据
    handelRowCancel(index) {
      if (this.isAdd) {
        this.handelRowDelete(index)
      } else {
        this.articleType === '指南共识'
          ? this.formOption.labels[index].editor = false
          : this.literatureFormOption.labels[index].editor = false
        this.isRowEditor = false
      }
    },
    // 判断是否为空
    isEmpty(val) {
      return val == null || !(Object.keys(val) || val).length
    },
    // 保存标签行数据
    handelRowSave(index, data) {
      const arr = Object.keys(data)
        .filter(item => item !== 'editor' && item !== '_index' && item !== '_rowKey')
        .map(key => isEmpty(data[key]))
      if (arr.indexOf(true) === -1) {
        if (this.articleType === '指南共识') {
          Object.keys(this.formOption.labels[index]).forEach(item => {
            this.formOption.labels[index][item] = data[item]
          })
          this.formOption.labelsText[index].bio_markers = this.formatLabelData(data.bio_markers, this.biomarkerOption)
          this.formOption.labelsText[index].disease = this.formatLabelData(data.disease, this.diseaseOption)
          this.formOption.labels[index].editor = false
        } else {
          Object.keys(this.literatureFormOption.labels[index]).forEach(item => {
            this.literatureFormOption.labels[index][item] = data[item]
          })
          this.literatureFormOption.labelsText[index].bio_markers = this.formatLabelData(data.bio_markers, this.biomarkerOption)
          this.literatureFormOption.labelsText[index].disease = this.formatLabelData(data.disease, this.diseaseOption)
          this.literatureFormOption.labels[index].editor = false
        }
        this.isRowEditor = false
        this.isAdd = false
      } else {
        Message.warning(errorMap[arr.indexOf(true)])
      }
    },
    // 删除标签行数据
    handelRowDelete(index) {
      this.isRowEditor = false
      this.isAdd = false
      this.articleType === '指南共识'
        ? this.formOption.labels.splice(index, 1)
        : this.literatureFormOption.labels.splice(index, 1)
    },
    // 添加标签行数据
    handelAddRow() {
      this.isAdd = true
      this.isRowEditor = true
      if (this.articleType === '指南共识') {
        this.formOption.labels.push({
          editor: true,
          field: [],
          bio_markers: [],
          disease: []
        })
        this.formOption.labelsText.push({
          bio_markers: [],
          disease: []
        })
      } else {
        this.literatureFormOption.labels.push({
          editor: true,
          field: [],
          bio_markers: [],
          disease: []
        })
        this.literatureFormOption.labelsText.push({
          bio_markers: [],
          disease: []
        })
      }
    },
    // 领域选择
    handelFieldChange(value, index) {
      if (this.articleType === '指南共识') {
        this.formOption.labels[index].bio_markers = []
        this.formOption.labels[index].disease = []
        this.formOption.labels[index].field = value
      } else {
        this.literatureFormOption.labels[index].bio_markers = []
        this.literatureFormOption.labels[index].disease = []
        this.literatureFormOption.labels[index].field = value
      }
      if (value.length) {
        fetchBiomarkers({
          fields: value.join(',')
        }).then(res => {
          console.log(res)
          if (res.code === 200) {
            this.biomarkerOption = res.data
          }
        })
        fetchDisease({
          field_id__in: value.join(',')
        }).then(res => {
          if (res.code === 200) {
            this.diseaseOption = res.data
          }
        })
      } else {
        this.biomarkerOption = []
        this.diseaseOption = []
      }
    },
    // 提交
    handleSubmit() {
      if (this.articleType === '指南共识') {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            const submitData = {
              article_type: [this.type],
              ...this.formOption,
              publish_year: dayjs(this.formOption.publish_year).format('YYYY'),
              interpreter: this.formOption.interpreter.filter(item => item.url !== '')
            }
            this.submitType === 'add'
              ? this.$emit('on-submit', submitData, 'guide')
              : this.$emit('on-editor', submitData)
          } else {
            Message.error('Fail!')
          }
        })
      } else {
        this.$refs.literatureRuleValidate.validate((valid) => {
          if (valid) {
            const submitData = {
              article_type: [this.type],
              ...this.literatureFormOption,
              publish_year: dayjs(this.literatureFormOption.publish_year).format('YYYY'),
              authors: this.literatureFormOption.authors.filter(Boolean),
              keywords: this.literatureFormOption.keywords.filter(item => item.key_word !== ''),
              population: this.literatureFormOption.population.filter(item => item !== '')
            }
            console.log(submitData, 'ddd')
            this.submitType === 'add'
              ? this.$emit('on-submit', submitData, 'other')
              : this.$emit('on-editor', submitData)
          } else {
            Message.error('Fail!')
          }
        })
      }
    },
    // 取消
    handelCancel() {
      if (this.articleType === '指南共识') {
        this.$refs.formValidate.resetFields()
      } else {
        this.$refs.literatureRuleValidate.resetFields()
      }
      this.$emit('on-cancel')
    }
  }
}
</script>

<style scoped lang="less">
.item-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .label-wrap {
    width: 80px;
    text-align: right;
    padding: 10px 12px 10px 0;
    box-sizing: border-box;
  }
}
.form-container {
  .form-body {
    height: 600px;
    padding: 16px;
    overflow-y: auto;
  }
}

.form-footer {
  margin-bottom: 0;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e8eaec;
  padding: 12px 18px;
}
.icon-area {
  & > em {
    margin-left: 8px;
    cursor: pointer;
  }

  .iconjian {
    color: #fe5332;
  }
  .iconjia {
    color: #007FFF;
  }
}

// .btn-wrap {
//   margin-bottom: 0;
//   display: flex;
//   justify-content: flex-end;

//   & /deep/ .ivu-form-item-content {
//     margin-left: 0 !important;
//   }
// }

.label-wrap {
  & /deep/ .ivu-form-item-content {
    margin-left: 0 !important;
  }
}

.table-card {
  .title {
    margin-bottom: 16px;
  }
}

.default-btn {
  border-color: #007FFF;
  color: #007FFF;
}
.delete-btn {
  border-color: #fe5332;
  color: #fe5332;
}
.primary-btn {
  border-color: #19be6b;
  color: #19be6b;
}
</style>