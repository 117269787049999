<template>
  <div>
    <Select v-model="continent" clearable placeholder="大洲" style="width:100px;" multiple>
      <Option v-for="item in continentList" :value="item.value" :key="item.value">{{ item.label }}</Option>
    </Select>
    <Select v-model="country" clearable placeholder="国家" style="width:100px;margin:0 12px;" multiple>
      <Option v-for="item in countryList" :value="item.value" :key="item.value">{{ item.label }}</Option>
    </Select>
    <Select v-if="country.indexOf('China') !== -1" v-model="province" clearable placeholder="省份" style="width:80px;" multiple>
      <Option v-for="item in provinceList" :value="item.value" :key="item.value">{{ item.label }}</Option>
    </Select>
  </div>
</template>

<script>
import { Select, Option } from 'view-design'
import { getProvinceList, getCountryList, getContinentList } from '@/api/zhiku/'
import Zh_CnConuntry from './conuntry.json'
export default {
  components: {
    Select,
    Option
  },
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      continent: [],
      country: [],
      province: [],
      continentList: [],
      countryList: [],
      provinceList: []
    }
  },
  created() {
    getContinentList().then(res => {
      if (res.code === 200) {
        this.continentList = res.data.map(item => ({
          ...item,
          label: item.name,
          value: item.name
        }))
      }
    })
  },
  watch: {
    modelValue: {
      handler(val) {
        if (val.length === 3) {
          this.continent = val[0]
          this.country = val[1]
          this.province = val[2]
        } else if (val.length === 2) {
          this.continent = val[0]
          this.country = val[1]
        } else if (val.length === 1) {
          this.continent = val[0]
        }
      }
    },
    continent(val) {
      getCountryList({ continent__in: val.join(',') }).then(res => {
        this.countryList = res.data.map(item => ({
          ...item,
          label: Zh_CnConuntry[item.name] || item.name,
          value: item.name
        }))
      })
      this.$emit('change', [val])
    },
    country(val) {
      if (val.indexOf('China') !== '-1') {
        getProvinceList({ country__in: val.join(',') }).then(res => {
          this.provinceList = res.data.map(item => ({
            ...item,
            label: item.name,
            value: item.name
          }))
        })
      } else {
        this.provinceList = []
      }
      this.$emit('change', [this.continent, val])
    },
    province(val) {
      this.$emit('change', [this.continent, this.country, val])
    }
  }
}
</script>
