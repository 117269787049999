<template>
  <div v-if="item">
    <div class="guide-item-wrap" v-if="item.article_data_type === 'guide'">
      <div class="title-wrap">
        <Checkbox :label="item.id"><span></span></Checkbox>
        <div class="title">{{ item.title }}</div>
      </div>
      <div class="check-item-wrap">
        <div class="field-item">
          <div class="label">发布机构：</div>
          <div class="value">{{ item.institute }}</div>
        </div>
        <div class="field-item">
          <div class="label">发表年份：</div>
          <div class="value">{{ item.publish_year }}</div>
        </div>
        <div class="bottom-item">
          <div class="field-item">
            <div class="label">原文下载：</div>
            <div class="value url" @click="handleUrl(item)">下载链接</div>
          </div>
          <div class="field-item" style="margin-left: 20px;">
            <div class="label">内容解读：</div>
            <div class="value">
              <div
                v-for="(c, index) in item.interpreter"
                :key="index"
                @click="handleClickContent(c)"
                :class="{'ddzj': c.name === '大道至检e课堂', 'ymt': c.name === '医脉通', 'yw': c.name === '原文解读'}"
              >{{ c.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="impact-factor-wrap">
        <div class="right" v-if="item.journal || item.impact_factor">
          <span class="journal" v-if="item.journal">{{item.journal.length > 64 ? `${item.journal.slice(0, 32)}...` : item.journal}} <span style="color: #007FFF">{{item.journal_type_info}}</span></span>
          <span class="factor" v-if="item.impact_factor">impact factor:{{item.impact_factor.toFixed(2)}}</span>
        </div>
      </div>
      <div class="btn-wrap">
        <div class="btn" @click="handleView(item)">查看</div>
        <div class="btn" @click="handelEditorClick(item)">编辑</div>
        <div class="btn delete" @click="handelDeleteClick(item)">删除</div>
      </div>
    </div>
    <div v-else class="list-item-wrap">
      <div class="list-item-header">
        <div class="left">
          <Checkbox :label="item.id"><span></span></Checkbox>
          <span @click="handleTitleClick(item)" class="title" v-html="item.title"></span>
        </div>
      </div>
      <div class="label-common author">
        作者：
        <span class="is-more span_val" ref="authors" v-if="item.other_info.authors.length">
          <span :class="{'ellipsis': isMore && !item.isMore}">{{setString(item.other_info.authors) || '-'}}</span>
          <span class="more" @click="isToggleMore" v-if="isMore">
          More
          </span>
        </span>
      </div>
      <div class="pmid-year">
        <div class="label-common year">
          发表年份：<span class="span_val">{{item.publish_year || '-'}}</span>
        </div>
        <div class="label-common pmid" v-if="item.other_info.pmid">
          PMID：<span class="span_val">{{item.other_info.pmid || '-'}}</span>
        </div>
      </div>
      <div class="label-common keywords" v-if="item.other_info.keywords && item.other_info.keywords.length">
        关键词：<span style="margin-left: 18px;color: #007FFF" class="span_val" v-html="setString(item.other_info.keywords) || '-'"></span>
      </div>
      <div class="article-info">
        <div class="label-common left">
          来源链接：<template v-if="item.source_url">
            <a :href="item.source_url" target="blank">
              <span class="pubmed" v-if="item.source === 'pubmed'">Pubmed</span>
              <span class="zhiwang" v-if="item.source === '知网'">中国知网</span>
              <span class="wanfang" v-if="item.source === '万方医学'">万方医学</span>
              <span class="zhyxqk" v-if="item.source === '中华医学期刊网'">中华医学期刊网</span>
            </a>
          </template>
          <template v-else>
            <a style="color: #494E53">-</a>
          </template>
        </div>

      </div>
      <div class="article-info journal">
        <div class="right" v-if="item.journal || item.impact_factor">
          <span class="journal" v-if="item.journal">{{item.journal.length > 64 ? `${item.journal.slice(0, 32)}...` : item.journal}} <span style="color: #007FFF">{{item.journal_type_info}}</span></span>
          <span class="factor" v-if="item.impact_factor">impact factor:{{item.impact_factor.toFixed(2)}}</span>
        </div>
      </div>
      <div class="article-info journal collect" v-if="item.created_time">
        收藏时间： {{item.created_time}}
      </div>
      <div class="btn-wrap">
        <div class="btn" @click="handleView(item)">查看</div>
        <div class="btn" @click="handelEditorClick(item)">编辑</div>
        <div class="btn delete" @click="handelDeleteClick(item)">删除</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox } from 'view-design'

export default {
  props: {
    item: {
      type: Object,
      default: () => null
    }
  },
  components: { Checkbox },
  data() {
    return {
      isMore: false
    }
  },
  computed: {
    setString() {
      return (val) => {
        return Array.isArray(val) && val.join(',')
      }
    }
  },
  watch: {
    item: {
      handler(val) {
        console.log(val)
        if (val) {
          this.$nextTick(() => {
            // if (this.$refs.authors.getBoundingClientRect().height > 21) {
            //   this.isMore = true
            // } else {
            //   this.isMore = false
            // }
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    handelEditorClick(item) {
      this.$emit('on-editor', item)
    },
    handelDeleteClick(item) {
      console.log(item)
      this.$emit('on-delete', item)
    },
    handleView(item) {
      this.$emit('on-view', item)
    },
    handleTitleClick(item) {
      if (item.source_url) {
        window.open(item.source_url)
      }
    },
    handleUrl(item) {
      if (item.source_url) {
        window.open(item.source_url)
      }
    },
    handleClickContent(item) {
      if (item.url) {
        window.open(item.url)
      }
    }
  }
}
</script>

<style scoped lang="less">
.guide-item-wrap {
  // display: flex;
  margin-top: 30px;
  position: relative;
  .title-wrap {
    display: flex;
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #202327;
      margin-bottom: 24px;
      line-height: 1em;
    }
  }
  .check-item-wrap {
    display: inline-block;
    // margin-left: 24px;

    .bottom-item {
      display: flex;
    }
  }

}
.btn-wrap {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -8px;
  display: flex;

  .btn {
    font-size: 16px;
    color: #007FFF;
    cursor: pointer;
    user-select: none;
    margin-right: 48px;
  }

  .delete {
    font-size: 16px;
    color: #FF5252;
  }
}

.field-item {
  display: flex;
  margin-bottom: 12px;
  .label {
    font-size: 14px;
    color: #8E9CA9;
  }

  .value {
    font-size: 14px;
    color: #494E53;
    display: flex;

    & > div {
      margin-right: 8px;
    }
  }

  .ddzj {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 77px;
    height: 24px;
    padding: 0 8px;
    background: linear-gradient(135deg, #70E2F2 0%, #58C2DD 100%);
    box-shadow: 0px 2px 6px 0px #61C7D5;
    border-radius: 4px;
    cursor: pointer;
    color: #fff;
  }
  .ymt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 76px;
    height: 24px;
    background: linear-gradient(135deg, #8BCAFF 0%, #3BA5FD 100%);
    box-shadow: 0px 2px 6px 0px rgba(59, 165, 253, 0.5);
    border-radius: 4px;
    cursor: pointer;
    color: #fff;
  }
  .yw {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 76px;
    height: 24px;
    background: linear-gradient(135deg, #C0B1FD 0%, #A587F3 100%);
    box-shadow: 0px 2px 6px 0px #B7A6F8;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
  }

  .url {
    width: 76px;
    height: 24px;
    background: linear-gradient(135deg, #8BCAFF 0%, #3BA5FD 100%);
    box-shadow: 0px 2px 6px 0px rgba(59,165,253,0.5);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
  }
}

.impact-factor-wrap {
  display: flex;
  justify-content: flex-end;

  .right {
    border: 1px solid #A2BDD9;
    border-radius: 5px;
    font-size: 14px;
    .journal {
      padding: 2px 10px;
      color: #A2BDD9;
    }
    .factor {
      color: #FFFFFF;
      padding: 2px 10px;
      background-color: #a2bdd9;
      border-bottom: 1px solid #A2BDD9;
    }
  }
}

.list-item-wrap {
  width: 100%;
  padding: 0;
  margin-top: 24px;
  // padding-bottom: 24px;
  position: relative;
  .list-item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      // align-items: center;

      & label {
        margin-top: 3px;
      }
      .ivu-checkbox {
        margin-top: -4px;
      }
      .title {
        line-height: 22px;
        font-size: 18px;
        color: #202327;
        font-weight: 700;
        cursor: pointer;
        // margin-left: 16px;
        // margin-top: 12px !important;

        &:hover {
          color:#007FFF;
        }
      }
      .is-new {
        margin-left: 4px;
        margin-right: 12px;
        margin-top: 2px;
        &>img {
          display: block;
          width: 45px;
          height: 20px;
        }
      }
    }
    .right {
      cursor: pointer;
      i {
        font-size: 16px;
        color: #B9C6D3;
      }
      .active {
        color: #007FFF;
      }
    }
  }
  .label-common {
    font-size: 14px;
    color: #8E9CA9;
    margin-bottom: 12px;
    .span_val {
      color: #494E53;
      margin-left: 32px;
    }
  }
  .author {
    margin-top: 24px;
    .is-more {
      margin-left: 74px;
      display: flex;
      margin-top: -20px;
      .ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .more {
        cursor: pointer;
        color: #007FFF;
      }
    }
  }
  .pmid-year {
    display: flex;
    align-items: center;
    .year {
      span {
        margin-left: 4px;
      }
    }
    .pmid {
      margin-left: 120px;
      span {
        margin-left: 22px;
      }
    }
  }
  .keywords {
    span {
      // margin-left: 18px;
      color: #007FFF;
    }
  }
  .article-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      a {
        margin-left: 4px;
        color: #fff;
        cursor: pointer;
      }
      .pubmed {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 77px;
        height: 24px;
        background: linear-gradient(135deg, #70E2F2 0%, #58C2DD 100%);
        box-shadow: 0px 2px 6px 0px #61C7D5;
        border-radius: 4px;
        cursor: pointer;
      }
      .zhiwang {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 76px;
        height: 24px;
        background: linear-gradient(135deg, #8BCAFF 0%, #3BA5FD 100%);
        box-shadow: 0px 2px 6px 0px rgba(59, 165, 253, 0.5);
        border-radius: 4px;
      }
      .wanfang {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 76px;
        height: 24px;
        background: linear-gradient(135deg, #C0B1FD 0%, #A587F3 100%);
        box-shadow: 0px 2px 6px 0px #B7A6F8;
        border-radius: 4px;
      }

      .zhyxqk {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 8px;
        height: 24px;
        background: #1C4C61;
        border-radius: 4px;
      }
    }
    .right {
      border: 1px solid #A2BDD9;
      border-radius: 5px;
      font-size: 14px;
      .journal {
        padding: 2px 10px;
        color: #A2BDD9;
      }
      .factor {
        color: #FFFFFF;
        padding: 2px 10px;
        background-color: #a2bdd9;
        border-bottom: 1px solid #A2BDD9;
      }
    }
  }
  .journal {
    width: 100%;
    justify-content: flex-end;
  }
  .collect {
    margin-top: 12px;
    font-size: 14px;
    color: #8E9CA9;
  }
  .abstract {
    margin-top: 24px;
    font-size: 14px;
    color: #8E9CA9;
    .exec {
      display: flex;
      .exec-words {
        cursor: pointer;
      }
      .iconfont {
        display: block;
        color: #A7ABB3;
        margin-left: 4px;
      }
      .fold {
        color: #4F7DD6;
        transform: rotateX(180deg);
        margin-top: -12px;
      }
      .word-active {
        padding-left: 16px;
      }
    }
    .active {
      width: 104px;
      height: 24px;
      color: #4F7DD6;
      border: 1px solid #4F7DD6;
      border-radius: 3px 3px 0 0;
      border-bottom: 2px solid #fff;
      position: relative;
      z-index: 10;
      margin-bottom: -1px;
    }
    .abstract-content {
      font-size: 14px;
      color: #494E53;
      padding: 30px 0 16px 0;
      text-align: justify;
      // word-break: break-all;
      // word-wrap: break-word;
      line-height: 24px;
      border-top: 1px solid #4F7DD6;
    }
  }
}
</style>