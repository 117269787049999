<template>
  <div v-if="item" class="meeting-item-wrap">
    <div style="flex: 1;">
      <div class="head-wrap">
        <div style="display: flex;">
          <Checkbox :label="item.id"><span></span></Checkbox>
          <div class="logo-wrap" v-if="item.logo_stream">
            <img :src="`${base64Map.get(item.logo.split('.').pop())}${item.logo_stream}`" alt="">
          </div>
          <div class="title-wrap">
            <div class="title" @click="handleFilePreview(item)">{{ item.title }}</div>
            <div class="year-wrap">
              <div>会议年份:</div>
              <div>{{ item.meeting_year }}</div></div>
          </div>
        </div>
        <Button @click="handleDownload">下载PDF</Button>
      </div>
      <div class="grid-container">
        <div v-for="(item, index) in fileList" :key="index" class="grid-item">
          <div class="index">题目: {{ index + 1 }}</div>
          <div class="title" @click="handleQuestion(item)">{{ item.title }}</div>
          <Icon style="cursor: pointer;" type="md-download" size="20" @click="handleFileClick(item)" />
        </div>
      </div>
      <div class="more-wrap" v-if="item.topics.length > 3" @click="handleMoreClick">more</div>
    </div>
    <div class="btn-wrap">
      <!-- <div class="preview" @click="handlePreview">查看</div> -->
      <div class="editor" @click="hanldeEditor">编辑</div>
      <div class="delete" @click="handlrDel">删除</div>
    </div>
  </div>
</template>

<script>
import { Checkbox, Button, Icon, Message } from 'view-design'
import { saveAs } from 'file-saver'
import { downloadFile } from '@/api/meeting'

export default {
  components: {
    Checkbox,
    Button,
    Icon
  },
  props: {
    item: {
      type: Object,
      default: () => null
    },
    activeIndex: {
      type: [Number, Object],
      default: () => null
    }
  },
  data () {
    return {
      showMore: false,
      base64Map: new Map([
        ['png', 'data:image/png;base64,'],
        ['jpg', 'data:image/jpg;base64,'],
        ['jpeg', 'data:image/jpeg;base64,'],
        ['gif', 'data:image/gif;base64,'],
        ['bmp', 'data:image/bmp;base64,']
      ])
    }
  },
  computed: {
    fileList() {
      return this.activeIndex === this.item.id ? this.item.topics : this.item.topics.slice(0, 3)
    }
  },
  methods: {
    handlePreview() {
      this.$emit('preview', this.item)
    },
    hanldeEditor() {
      this.$emit('editor', this.item)
    },
    handlrDel() {
      this.$emit('del', this.item)
    },
    handleMoreClick() {
      this.$emit('more')
    },
    handleDownload() {
      if (this.item.file.indexOf('http') === -1) {
        Message.warning('文件不存在')
        return
      }
      const fileName = decodeURIComponent(this.item.file).split('/').pop()
      downloadFile({ file: this.item.file }).then(res => {
        saveAs(res, fileName)
      })
    },
    handleFileClick(item) {
      if (item.file.indexOf('http') === -1) {
        Message.warning('文件不存在')
        return
      }
      const fileName = decodeURIComponent(item.file).split('/').pop()
      downloadFile({ file: item.file }).then(res => {
        saveAs(res, fileName)
      })
    },
    handleFilePreview(item) {
      window.open(item.file_preview)
    },
    handleQuestion(item) {
      window.open(item.file_preview)
    }
  }
}
</script>

<style lang="less" scoped>
.meeting-item-wrap {
  margin-top: 32px;
  display: flex;
}

.btn-wrap {
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  margin-left: 100px;

  .preview {
    color: #0082FF;
  }

  .editor {
    color: #0082FF;
    margin-left: 16px;
  }

  .delete {
    color: #FF4D4F;
    margin-left: 16px;
  }
}
.head-wrap {
  display: flex;
  // align-items: center;
  justify-content: space-between;

  .logo-wrap {
    width: 272px;
    height: 50px;
    background: #D8D8D8;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .title-wrap {
    margin: 0 32px 0 16px;
    line-height: 1;
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #202327;
      cursor: pointer;
    }
    .year-wrap {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-top: 12px;

      > div:first-child {
        color: #8E9CA9;
      }

      > div:last-child {
        color: #494E53;
        margin-left: 8px;
      }
    }
  }
}

.grid-container {
  // display: grid;
  // grid-template-columns: repeat(3, 1fr);
  margin-top: 21px;

  .grid-item {
    border: 1px solid #ccc;
    border-bottom: none;
    // margin-left: -1px;
    // margin-top: -1px;
    padding: 10px 32px;
    display: flex;
    align-items: center;

    &:last-child {
      border-bottom: 1px solid #ccc;
    }

    .index {
      color: #8E9CA9;
      margin-right: 8px;
    }

    .title {
      flex: 1;
      color: #0082FF;
      cursor: pointer;
    }
  }
}

.more-wrap {
  margin-top: 12px;
  font-size: 14px;
  color: #0082FF;
  line-height: 14px;
  cursor: pointer;
}

</style>