<template>
  <div>
    <Z-Table v-bind="$attrs">
      <template v-for="(value, name) in $scopedSlots" #[name]="scopeData">
        <slot :name="name" v-bind="scopeData"></slot>
      </template>
      <template #action="{row, index}">
        <div v-if="row.editor">
          <Button class="primary-btn" size="small" type="default" @click="handelRowSave(index, row)">{{
            isAdd ? '添加' : '保存'
          }}</Button>
          <Button
            :class="isAdd ? 'delete-btn' : 'default-btn'"
            style="margin-left: 16px;"
            size="small"
            type="default"
            @click="handelRowCancel(index)"
            >{{ isAdd ? '删除' : '取消' }}</Button
          >
        </div>
        <div v-else>
          <Button
            :class="{ 'default-btn': !isRowEditor }"
            :disabled="isRowEditor"
            size="small"
            type="default"
            @click="handelRowEditor(index)"
            >编辑</Button
          >
          <Button
            :class="{ 'delete-btn': !isRowEditor }"
            :disabled="isRowEditor"
            style="margin-left: 16px;"
            size="small"
            type="default"
            @click="handelRowDelete(index)"
            >删除</Button
          >
        </div>
      </template>
    </Z-Table>
    <Button style="margin-top: 16px;" :disabled="isRowEditor" type="dashed" icon="md-add" long @click="handelAddRow"
      >添加</Button
    >
  </div>
</template>

<script>
import { Table, Button, Message } from 'view-design'

export default {
  components: {
    'Z-Table': Table,
    Button
  },
  props: {
    isAsync: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isAdd: false,
      isRowEditor: false
    }
  },
  methods: {
    handelRowEditor(index) {
      this.$attrs.data[index].editor = true
      this.isRowEditor = true
    },
    handelRowDelete(index) {
      this.isRowEditor = false
      this.isAdd = false
      if (!this.isAsync) {
        this.$attrs.data.splice(index, 1)
      }
      this.$emit('delete', this.$attrs.data[index], index)
    },
    handelRowSave(index, row) {
      // 判断 row 对象中除了_开头的属性外，是否有空值
      const keys = Object.keys(row).filter(item => !item.startsWith('_'))
      const isNull = keys.some(key => !row[key])
      if (isNull) {
        Message.error('请填写完整信息')
        return
      }
      if (this.isAdd) {
        this.$emit('add', row, index)
      } else {
        this.$emit('save', row)
      }
      if (!this.isAsync) {
        this.isRowEditor = false
        this.isAdd = false
        this.$attrs.data[index].editor = false
      }
    },
    handelRowCancel(index) {
      if (this.isAdd) {
        this.handelRowDelete(index)
      } else {
        this.isRowEditor = false
        this.$attrs.data[index].editor = false
      }
    },
    handelAddRow() {
      this.isAdd = true
      this.isRowEditor = true
      const keys = this.$attrs.columns.map(item => item.slot).filter(item => item !== 'action')
      console.log(this.$attrs.data, 'columns')
      this.$attrs.data.push(
        keys.reduce(
          (obj, key) => {
            obj[key] = ''
            return obj
          },
          { editor: true }
        )
      )
    }
  }
}
</script>

<style lang="less" scoped>
.default-btn {
  border-color: #007fff;
  color: #007fff;
}
.delete-btn {
  border-color: #fe5332;
  color: #fe5332;
}
.primary-btn {
  border-color: #19be6b;
  color: #19be6b;
}
</style>
