<template>
  <div class="batch-wrap">
    <div class="batch-body">
      <Select v-model="type" style="margin-bottom: 16px;">
        <Option value="0">指南上传</Option>
        <Option value="1">文献上传</Option>
      </Select>
      <Upload
        type="drag"
        :before-upload="handleUpload"
        action="''"
        accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        :on-success="handelSuccess"
        :on-error="handelError"
        >
        <div class="upload-wrap">
          <img class="icon" src="@/assets/imgs/upload.png" alt="">
          <p>点击或将文件拖拽到这里上传</p>
          <p>支持扩展名 .xls, .xlsx 格式</p>
        </div>
      </Upload>
      <ul v-if="file" class="upload-list">
        <li class="file-item">
          <Icon type="ios-stats" />
          <span class="name">{{ file.name }}</span>
        </li>
      </ul>
    </div>
    <div class="batch-footer">
      <div style="cursor: pointer;" @click="handelDownload"><Icon type="md-download" color="#007FFF" size="16" style="margin-right: 4px;" />下载模板</div>
      <Button :loading="loading" type="primary" @click="handelPrimaryClick">确定上传</Button>
    </div>
  </div>
</template>

<script>
import { Upload, Select, Option, Button, Icon, Message } from 'view-design'
import { uploadBatchGuide, uploadBatchArticle } from '@/api/upload'

export default {
  components: {
    Upload,
    Select,
    Option,
    Button,
    Icon
  },
  data() {
    return {
      extraData: {},
      type: '0',
      file: null,
      loading: false
    }
  },
  methods: {
    handleUpload(file) {
      console.log(file)
      this.file = file
      return false
    },
    handelSuccess(response) {
      if (response.code === 200) {
        console.log(response.data)
        this.$emit('upload-success', response.data)
      }
    },
    handelError(error) {
      console.log(error)
    },
    handelPrimaryClick() {
      if (this.file) {
        this.loading = true
        const formdata = new FormData()
        formdata.set('file', this.file)
        if (this.type === '0') {
          uploadBatchGuide(formdata).then(res => {
            console.log(res)
            this.loading = false
            if (res.code === 200) {
              Message.success('上传成功')
              this.$emit('on-submit')
            }
          }).catch(err => {
            console.log(err)
            this.loading = false
            // Message.fail('数据重复')
          })
        } else {
          uploadBatchArticle(formdata).then(res => {
            console.log(res)
            this.loading = false
            if (res.code === 200) {
              Message.success('上传成功')
              this.$emit('on-submit')
            }
          }).catch(err => {
            console.log(err)
            this.loading = false
            // Message.fail('数据重复')
          })
        }
      } else {
        Message.warning('请选择文件上传')
      }
    },
    handelDownload() {
      if (this.type === '0') {
        const a = document.createElement('a')
        a.href = '/static/指南批量上传模板.xlsx'
        a.download = '指南批量上传模板.xlsx'
        a.click()
      } else {
        const a = document.createElement('a')
        a.href = '/static/文献批量上传模板.xlsx'
        a.download = '文献批量上传模板.xlsx'
        a.click()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.batch-wrap {

  .batch-body {
    padding: 16px;
  }
  .upload-wrap {
    height: 152px;
    background: #F1F5F8;
    border-radius: 6px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .icon {
      width: 32px;
      height: 32px;
    }

    & > p {
      &:nth-of-type(1) {
        color: #1F2428;
        margin-top: 16px;
      }
      &:nth-of-type(2) {
        margin-top: 8px;
        font-size: 12px;
        color: #8B9CAB;
      }
    }
  }

  .batch-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 18px;
    border-top: 1px solid #e8eaec;
  }

  .upload-list {
    margin-top: 8px;

    .file-item {
      padding: 4px;
      color: #515a6e;
      border-radius: 4px;
      transition: background-color .2s ease-in-out;
      overflow: hidden;
      position: relative;

      &:hover {
        background: #f3f3f3;
      }

      .name {
        margin-left: 4px;
      }
    }
  }
}
</style>