<template>
  <div class="user-card-item">
    <div class="item-header">
      <div class="left">
        <div class="name">{{item.name}}</div>
      </div>
      <Checkbox :label="item.id">
        <span class="title" style="margin-left: -16px"></span>
      </Checkbox>
    </div>
    <div class="box_2">
      <Dropdown trigger="click" @on-click="isDropClick">
        <span class="role">{{item.role_info ? item.role_info.name : '-'}}<Icon type="ios-arrow-down" style="margin-left: 4px"></Icon></span>
        <DropdownMenu slot="list">
          <DropdownItem v-for="role in roles" :key="role.label" :name="role.value">
            {{role.label}}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <slot></slot>
    </div>
    <div class="user-info-item" :style="{ marginTop: '10px' }">
      <div class="left">
        <i class="iconfont iconshijian"></i>
        {{item.created_time}}
      </div>
        <iveiwSwitch size="small" v-model="item.banned" @on-change="isSwitch"/>
    </div>
  </div>
</template>

<script>
import { Checkbox, Dropdown, DropdownMenu, Switch, DropdownItem, Icon } from 'view-design'
export default {
  name: 'UserCard',
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    roles: {
      type: Array,
      default: () => []
    }
  },

  components: {
    Checkbox,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    iveiwSwitch: Switch,
    Icon
  },

  computed: {},

  methods: {
    isSwitch(bool) {
      this.$emit('switch', { id: this.item.id, bool })
    },
    isDropClick(id) {
      this.$emit('drop', { user_id: this.item.id, role_id: id })
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>