<template>
  <div class="subject-page">
    <div class="btn-wrap">
      <Button type="primary" ghost @click="addHandle">新增</Button>
    </div>
    <Z-Table :columns="columns" :data="data"/>
    <div style="display: flex;justify-content: flex-end;margin-top: 24px;">
      <Page :total="total" :current="page" @on-change="pageChange"/>
    </div>
    <UploadForm
      v-model="show"
      title="编辑"
      :editor-data="form"
      @refresh="handleRefresh"
    ></UploadForm>
  </div>
</template>

<script>
import { Table, Button, Page, Message, Modal } from 'view-design'
import { deleteObject, editObject, getObjectList } from '@/api/zhiku'
import UploadForm from './uploadForm.vue'
export default {
  components: {
    'Z-Table': Table,
    Button,
    Page,
    UploadForm
  },
  data() {
    return {
      page: 1,
      page_size: 10,
      total: 0,
      data: [],
      form: {
        name: '',
        logo: ''
      },
      show: false,
      columns: [
        {
          title: '序号',
          type: 'index',
          width: 120,
          align: 'center'
        },
        {
          title: '主题名称',
          key: 'name'
        },
        {
          title: '主题状态',
          key: 'label_type',
          render: (h, params) => {
            const { row } = params
            return h(
              'div',
              row.is_activate
                ? '可见'
                : '不可见'
            )
          }
        },
        {
          title: '操作',
          slot: 'action',
          width: 240,
          render: (h, params) => {
            const { row } = params
            return (
              <div>
                {
                  row.is_activate ? (
                    <Button size="small" type="error" ghost onClick={() => this.editStatusHandle(row)}>隐藏</Button>
                  ) : (
                    <Button size="small" type="primary" ghost onClick={() => this.editStatusHandle(row)}>发布</Button>
                  )
                }

                <Button style={{ marginLeft: '8px' }} size="small" type="primary" ghost onClick={() => this.editHandle(row)}>编辑</Button>
                <Button style={{ marginLeft: '8px' }} size="small" type="error" ghost onClick={() => this.deleteHandle(row)}>删除</Button>
              </div>
            )
          }
        }
      ]
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    pageChange(page) {
      this.page = page
      this.fetchData()
    },
    fetchData() {
      getObjectList({
        page: this.page,
        page_size: this.page_size
      }).then(res => {
        if (res.code === 200) {
          this.data = res.data.map((item, index) => ({
            ...item,
            index: 10 * (this.page - 1) + index + 1
          }))
          this.total = res.page.count
        }
      })
    },
    handleRefresh() {
      this.fetchData()
    },
    addHandle() {
      // this.form = {
      //   name: '',
      //   data: ''
      // }
      this.show = true
    },
    editHandle(item) {
      this.form = {
        name: item.name,
        logo: item.logo,
        logo_stream: item.logo_stream,
        logo_h5: item.logo_h5,
        logo_h5_stream: item.logo_h5_stream,
        id: item.id
      }
      this.show = true
    },
    editStatusHandle(item) {
      editObject(item.id, {
        is_activate: !item.is_activate
      }).then(res => {
        if (res.code === 200) {
          this.fetchData()
          Message.info('修改成功')
        } else {
          Message.error(res.message)
        }
      })
    },
    deleteHandle(item) {

      Modal.confirm({
        title: '是否删除此主题？',
        onOk: () => {
          deleteObject(item.id).then(res => {
            if (res.code === 200) {
              this.page = 1
              this.fetchData()
              Message.info('删除成功')
            } else {
              Message.error(res.message)
            }
          })
        }
      })

    }
  }
}
</script>

<style lang="less" scoped>
.subject-page {
  background-color: #fff;
  border-radius: 0 0 12px 12px;
  min-height: calc(100vh - 320px);
  padding: 24px;

  .btn-wrap {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
  }
}
</style>
