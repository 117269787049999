<template>
  <div class="feedback-wrap">
    <div class="feedback-header">
      <div class="header-item">
        <div class="label">反馈日期</div>
        <DatePicker type="daterange" placement="bottom-end" placeholder="请选择时间" style="width: 200px" @on-change="handleDate" @on-clear="isTimeClear"></DatePicker>
      </div>
      <div class="header-item">
        <div class="label">搜索</div>
        <!-- search -->
        <Input placeholder="请输入姓名/手机号/单位/等信息" style="width: 372px" v-model="search" @on-change="handleChange" />
      </div>
    </div>
    <div class="feedback-lists">
      <div class="user-card-head">
        <div class="left">
          <Checkbox
            :indeterminate="unsure"
            :value="checkAll"
            @click.prevent.native="isCheckAll">
            <span class="all">全选</span>
          </Checkbox>
          <Divider type="vertical" />
          <div class="user-info">
            共<span>{{total}}</span>位用户，已选<span>{{checkAllGroup.length}}</span>位
          </div>
        </div>
        <div class="right" @click="isFeedbackExport" v-if="checkAllGroup.length">
          <i class="iconfont iconxiazai1"></i>
        </div>
      </div>
      <div class="user-feed-wrap">
        <CheckboxGroup
          v-model="checkAllGroup"
          @on-change="checkAllGroupChange"
        >
          <template v-if="feeds.length">
            <FeedItem
              v-for="(item, index) in feeds"
              :key="item.id"
              :item="item"
              :itemKey="index"
              @more="toggleMore"
            />
          </template>
          <template v-else>
            <div class="empty">
              <img src="../../../assets/imgs/empty.png">
            </div>
          </template>
        </CheckboxGroup>
      </div>
    </div>
    <div class="feedback-footer">
      <Page
        :current="page"
        :total="total"
        show-elevator
        @on-change="isPageChange"
      />
    </div>
  </div>
</template>

<script>
import { DatePicker, Input, Checkbox, CheckboxGroup, Divider, Page } from 'view-design'
import { vueDebounce, deleteObjEmptyData, setUserCommonExport } from '@/utils/tools'
import FeedItem from '../components/FeedItem'
import { fetchSystemFeedBack } from '@/api/'
export default {
  name: 'FeedBack',
  data () {
    return {
      unsure: false,
      checkAll: false,
      checkAllGroup: [],
      total: 0,
      page: 1,
      feeds: [],
      search: '',
      date: []
    }
  },

  components: {
    DatePicker,
    Input,
    Checkbox,
    Divider,
    FeedItem,
    Page,
    CheckboxGroup
  },

  computed: {},

  created() {
    this.fetchList()
  },

  methods: {
    fetchList() {
      const option = {
        created_at__date__gte: this.date.length ? this.date[0] : '',
        created_at__date__lte: this.date.length ? this.date[1] : '',
        search: this.search,
        page: this.page
      }
      fetchSystemFeedBack(deleteObjEmptyData(option)).then(res => {
        const { data, page } = res.data
        this.feeds = data.map(item => ({
          ...item,
          isMore: false
        }))
        this.total = page.count
      })
    },
    isFeedbackExport() {
      const option = {
        id__in: this.checkAllGroup.join(',')
      }
      setUserCommonExport(option, 'users/feedback_export', {}, true)
      this.commonRest()
    },
    toggleMore(key) {
      this.feeds[key].isMore = !this.feeds[key].isMore
    },
    isCheckAll() {
      if (this.unsure) {
        this.checkAll = false
      } else {
        this.checkAll = !this.checkAll
      }
      this.unsure = false
      if (this.checkAll) {
        const temp = []
        this.feeds.forEach(item => temp.push(item.id))
        this.checkAllGroup = temp
      } else {
        this.checkAllGroup = []
      }
    },
    checkAllGroupChange(data) {
      if (data.length === this.feeds.length) {
        this.unsure = false
        this.checkAll = true
      } else if (data.length > 0) {
        this.unsure = true
        this.checkAll = false
      } else {
        this.unsure = false
        this.checkAll = false
      }
    },
    commonRest() {
      this.checkAll = false
      this.checkAllGroup = []
      this.unsure = false
    },
    isPageChange(val) {
      this.page = val
      this.commonRest()
      this.fetchList()
    },
    isDeounceChange: vueDebounce('handleSearch', 1000),

    handleChange(e) {
      if (e.target.value.trim()) {
        this.isDeounceChange(e.target.value)
      } else {
        if (!e.target.value.trim()) {
          this.search = ''
          this.fetchList()
        }
      }
    },
    // debounce
    handleSearch(val) {
      this.search = val
      // 重置选中 和页码
      this.commonRest()
      this.page = 1
      this.fetchList()
    },
    handleDate(date) {
      this.page = 1
      this.date = date
      this.commonRest()
      this.fetchList()
    },
    isTimeClear() {
      this.date = []
      this.commonRest()
      this.page = 1
      this.fetchList()
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>